@import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@500;700&family=Quicksand:wght@400;500;600;700&display=swap');

* {
    text-decoration: none !important;
    outline: 0 !important;
}

a.open_app_header {
    display: none
}

@media(max-width:768px) {
    a.open_app_header {
        display: block;
        position: fixed;
        right: -3px;
        top: 50%;
        z-index: 9;
        font-weight: bold;
        background: #ae0a0b;
        color: #ffffff !important;
        border-radius: 20px 0 0 20px;
        padding: 7px 15px;
        border: 1px solid #ffffff;
        font-size: 15px
    }
}

/* 
@font-face {
    font-family: 'sandesh';
    font-style: normal;
    src: URL('../assets/fonts/shree-slall-new.ttf') format('truetype');
}

@font-face {
    font-family: 'sandesh';
    font-style: italic;
    src: URL('../assets/fonts/shree-slall-i.ttf') format('truetype');
} */

body {
    font-family: 'Quicksand', 'Hind Vadodara', monospace;
    /* font-family: 'sandesh', 'Quicksand', 'Hind Vadodara', sans-serif; */
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    background-color: #fff;
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

.ad {
    padding: 100px;
    width: 100%;
    background-position: center 60%;
}

img {
    max-width: 100%;
    border-radius: 5px;
}

footer {
    background: #444;
    color: #fff;
}

.footer-bottom {
    background: #000;
    color: #fff;
    padding: 10px 0
}

.footer-menu ul {
    padding-left: 15px;
}

.footer-menu .footer-after-half-border:after {
    position: absolute;
    content: '';
    background: #AE0A0B;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
}

.footer-menu .footer-after-half-border {
    position: relative;
    padding-bottom: 10px;
}

.businesswidget {
    text-align: center;
}

.businesswidget * {
    width: 100% !important;
}

.footer-menu h6 {
    padding-bottom: 10px;
    margin: 15px 0;
    font-weight: bold;
}

.footer-right {
    background: #333;
    position: relative
}

.footer-livetv {
    position: absolute;
    /* bottom: 0; */
    margin-top: 27px;
    left: 0;
    width: 100%;
    background: #000;
    padding: 30px 0;
}

.footer-livetv h4 {
    font-weight: bold
}

.footer-livetv h4 .btn {
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
    background: #AE0A0B;
    border: 0;
    vertical-align: top;
    margin-left: 10px;
}

.footer-livetv h4 .btn i {
    margin-right: 10px;
}

.footer-bottom ul.list-inline {
    margin-bottom: 0;
}

.footer-bottom ul.list-inline li {
    vertical-align: middle
}

.footer-bottom ul.list-inline {
    margin-bottom: 0;
}

.footer-bottom p a {
    display: inline-block;
}

.footer-bottom p a i {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    vertical-align: middle;
}

.footer-bottom p a i.fa-facebook {
    color: #0076FB;
}

.footer-bottom p a i.fa-twitter {
    color: #1CA2F1;
}

.footer-bottom p a i.fa-instagram {
    color: #D64078;
}

.footer-bottom p a i.fa-youtube {
    color: #FE0000;
}

.footer-bottom p a i.fa-android {
    color: #ACCD41;
}

.footer-bottom p a i.fa-apple {
    color: #B265C4;
}

.follow-us {
    /* border-right: 1px solid #f9f9f9; */
    text-align: center;
}

.download-app {
    text-align: center;
}

footer a,
.footer-bottom a {
    color: #fff
}

footer a:hover,
.footer-bottom a:hover {
    color: #ccc;
    transition-duration: .3s
}

img.certi {
    width: 90px;
    height: auto;
    margin: auto;
    display: block
}

.footer-hr {
    margin-top: 0rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.footer hr {
    border-color: #494949 !important
}

span.badge {
    /* border-radius: 8px 8px 0 0; */
    padding: 13px 15px;
    height: 41px;
    font-size: 15px;
    margin-bottom: 15px;
    /* cursor: pointer; */
    color: #fff;
    box-shadow: 2px 3px 9px grey;
    background-color: #AE0A0B;

}

.single-blog .blog-details {
    /* padding: 7px 15px; */
    padding: 7px 5px;
}

.blog-details p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

span.blog-time i {
    color: #AE0A0B;
    margin-right: 10px;
}

p.blog-counts {
    display: none;
    margin-bottom: 0;
    background: #AE0A0B;
    color: #fff;
    font-size: 12px;
    padding: 4px 10px;
}

p.blog-counts i {
    margin: 0 5px;
}

p.blog-counts a {
    color: #fff;
}

.related-blogs {
    display: flex;
    /* padding: 5px; */
    /* padding: 13px 10px 10px 10px; */
    padding: 8px 10px 7px 10px;
    border-top: 1px solid #ccc;
}

.related-blogs img {
    width: 90px !important;
    /* display: block; */
    /* padding: 16px 0; */
    width: 100%;
}

.related-blogs .blog-details {
    padding: 0 15px;
}

.single-blog .blog-details,
.related-blogs {
    width: 100%;
    background: #fff;
    color: #333;
    text-align: left;
}

.rb-tab {
    /* background-color: #2c2c34 !important; */
    color: #333333 !important;
}

.rb-tab:hover {
    color: #AE0A0B !important;
}

.related-blogs .blog-details .blog-details-Technology a {
    font-size: 13px;
    line-height: 18px;
    display: block;
}

.related-blogs .blog-details .blog-details-Technology span.blog-time {
    font-size: 12px;
}

.blog-time {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
}

.single-blog a {
    color: #333;
}

a.related-blogs:hover {
    color: #AE0A0B;
    transition-duration: .3s
}

section.blogs,
section.lifestyle,
section.sport,
section.business {
    padding: 15px 0
}

section.gallery {
    /* background: url('../assets/gallerybg.jpg'); */
    padding: 15px 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

section.gallery:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: gray; */
    /* background: rgb(0 0 0 / 35%); */
}

section.gallery .container {
    position: relative;
    z-index: 1;
}

/* .business .blog-details {
    padding: 10px 5px;
} */

.gallery-sec {
    display: flex;
    margin: 0 -10px;
    user-select: none;
}

.gallery-sec .single-photo {
    position: relative;
    width: 100%;
    margin: 0 10px;
}

.gallery-sec .single-photo:hover span.caption {
    padding: 25px 5px;
    border-radius: 15px 15px 0 0;
    transition-duration: .3s
}

.gallery-sec .single-photo span.caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 92.5%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-align: center;
    padding: 5px;
}

.gallery-sec .single-photo img {
    height: 390px;
    width: 100%;
}

.bg-gray {
    background: #f0f0f0;
}

.nav-tabs .nav-link {
    color: #333;
    font-weight: bold
}

.nav-tabs .nav-link.active {
    background: #ae0a0b;
    color: #fff;
    border-radius: 5px !important;
}

.nav-tabs-guj .nav-link {
    background: #fff;
    color: #000;
    opacity: 0.5;
}

.nav-tabs-guj .nav-link.active {
    background: #fff;
    color: #AE0A0B;
    opacity: 1;
}

.astro p b {
    cursor: pointer;
    background: #ff00001c;
    width: max-content;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.astro p span {
    display: block;
    padding: 5px
}

.astro p span,
.astro li.active {
    background: #f6f6f6;
    width: 100%;
}


@media (min-width: 1200px) {
    .container {
        max-width: 1366px;
    }

    .menu {
        height: 41px;
    }

    .menu-page-social-gif-rathyatra-m {
        display: none !important;
    }
}

.nightmode {
    filter: invert(1) hue-rotate(45deg);
    transition: filter 0.3s;
}

.nightmode iframe,
.nightmode img {
    filter: invert(1) hue-rotate(-45deg);
}

/* .ad-1 {
      background: #000;
  }
  
  .ad-1 a {
      display: none;
      overflow: hidden;
      display: block;
  } */

.ad-1 img {
    width: 100%;
    display: block;
}

.topbar {
    /* background: #ae0a0b; */
    color: #000 !important;
}

.topbar a {
    color: #000;
    padding: 9px;
    display: block;
    font-size: 18px;
}

a.signin.signin-user-icon.cursor-pointer {
    width: auto !important;
}

.topbar a.signin.cursor-pointer {
    text-transform: capitalize;
    white-space: nowrap;
    max-width: 120px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topbar ul,
.topbar li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.topbar li {
    display: inline-block;
    vertical-align: middle;
}

.topbar .icons,
.topbar .contacts {
    display: inline-block;
}

.topbar .icons {
    background: #ae0a0b;
    font-size: 19px;
}

.icons {
    font-size: 19px;
}

.icons li {
    vertical-align: middle;
}

.keywords .icons a {
    padding: 8px 12.2px;
}

.topbar .rightbar ul li:last-child a,
.topbar .icons a {
    /* padding: 9px 15px; */
    padding: 6px 8px;
}

.topbar .contacts a i {
    margin-right: 5px;
}

.topbar .rightbar {
    text-align: right;
}

.topbar .rightbar ul li:last-child a {
    /* background: #ae0a0b; */
}

.topbar .contacts a {
    font-size: 12px;
    transform: translateY(-2px);
}

.header .logo {
    padding: 25px 0;
}

.header .logo a {
    display: block;
}

.header .logo img {
    display: block;
    width: 100%;
}

.header .logo .brand img {
    height: 80px;
    margin-top: 8px;
    width: auto;
}

.menu {
    /* background: rgba(255, 255, 255, .95); */
    background: #ae0a0b;
    /* background: #fff; */
    position: sticky !important;
    top: -1px;
    z-index: 99999999;
}

.menu-page {
    position: sticky !important;
    background-color: rgba(255, 255, 255, 0.95);
    top: 40px;
    z-index: 99999998;
    border-bottom: solid 1px #e3e3e3;
    border-top: solid 1px #ae0a0b;
}

.menu-dropdown {
    z-index: 9;
}

.borderoverlap {
    position: relative;
}

.bordersLeftRight {
    width: 90%;
    margin: auto;
    margin-top: 45px;
}

.borderoverlap:after,
.borderoverlap::before {
    content: "";
    left: 15px;
    height: 90%;
    top: 0;
    width: 1px;
    position: absolute;
    background-color: rgb(221 221 221 / 55%);
}

.borderoverlap::before {
    right: 15px;
    left: auto;
}

.borderVideoBottom .mb-3:first-child a {
    border-bottom: 2px solid rgb(221 221 221 / 55%);
    padding-bottom: 12px;
}

/* .menu ul, .menu li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: inline-block;
  }*/

.webMenu.menu a i.fas {
    transform: translateY(-1px);
}

.menu a i.fas {
    transform: translateY(1px);
    margin: 0;
}

.keywords li.menu-page-social-icons {
    padding: 10px 7px;
}

.menu a {
    display: block;
    color: #fff !important;
    position: relative;
    text-transform: uppercase;
    font-weight: 600 !important;
    padding: 10px 10px;
    font-size: 14px;
    transition: all .3s;
}

.menu .left-side i.fas {
    float: right;
    transform: translateY(3px);
}

.menu ul ul a {
    font-size: 12px;
    padding: 7px 12px !important;
}

.dropdown-menu {
    border-radius: 0px !important;
}

.menu li ul {
    /* transition: all 0.01s; */
    transition: none !important;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 9;
    background: #ae0a0b;
    left: 0;
    top: 100%;
    min-width: 160px;
    /* border-radius: 0 0 4px 4px; */
}

.modal-backdrop {
    z-index: 99999999 !important;
}

.modal {
    z-index: 99999999999 !important;
}

.menu li:hover ul {
    /* transition: all 0.1s; */
    opacity: 0.9;
    visibility: visible;
}

.menu li {
    position: relative;
}

.menu li ul li {
    display: block;
}

.menu a i {
    line-height: normal;
    display: inline-block;
    vertical-align: text-top;
    margin-left: 5px;
}

.keywords ul {
    min-height: 40px;
}

.keywords ul,
.keywords li {
    list-style-type: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.keywords a {
    display: block;
    color: #333;
    padding: 10px 8px;
    font-weight: 500;
}

.keywords .time {
    text-align: right;
    padding: 10px 0;
}

.keywords .time b {
    display: inline-block;
    min-width: 55px;
}

.home {
    padding: 15px 0;
}

.home .story {
    display: block;
    margin-bottom: 10px;
}

.home .caption {
    position: relative;
}

.home .caption img {
    display: block;
    width: 100%;
    transition: opacity 0.3s;
    /*opacity: 0.9;*/
}

.home .caption span {
    position: absolute;
    height: 41px;
    color: #fff;
    background: #ae0a0b;
    bottom: 2px;
    left: 2px;
    padding: 8px 15px;
    border-top-right-radius: 5px;
    font-weight: 600;
    font-size: 18px;
}

.cap-post-head-news {
    position: absolute;
    color: #333 !important;
    /* background: #AE0A0B; */
    bottom: 0px;
    left: 0px;
    padding: 5px 10px;
    /* border-top-right-radius: 5px; */
    font-weight: 600;
    font-size: 14px !important;
}

.cap-post-head-guj {
    /* position: absolute; */
    color: #333 !important;
    /* background: #AE0A0B; */
    bottom: 0px;
    left: 0px;
    padding: 5px 10px;
    /* border-top-right-radius: 5px; */
    font-weight: 600;
    font-size: 14px !important;
}

.main-blog-guj-hover:hover .cap-post-head-guj {
    /* position: absolute; */
    color: #fff !important;
    background: #ae0a0b !important;
    bottom: 0px;
    left: 0px;
    padding: 5px 10px;
    /* border-top-right-radius: 5px; */
    font-weight: 600;
    font-size: 14px !important;
}

.main-blog-guj-hover:hover .cap-post-head-guj a {
    color: #fff !important;
}

.home .story p {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
}

.home .substory .story p {
    font-size: 16px;
}

.home .story:hover img {
    opacity: 1;
}

.home .story:hover p {
    color: #ae0a0b;
}

.home .livetv {
    margin-bottom: 10px;
}

/* .home .livetv iframe {
      border: 0;
      width: 100%;
      height: 197px;
      box-shadow: 0 2px 4px #ccc;
      border-radius: 4px;
  } */

.home .livetv .iframeLiveTv {
    min-height: 180px;
}

.home .livetv iframe {
    border: 0;
    width: 100%;
    height: 180px;
    box-shadow: 0 2px 4px #ccc;
    border-radius: 4px;
}

.home .s-story a:hover {
    color: #fff;
}

.s-story {
    /* background-color: #262626; */
    background-color: rgba(255, 255, 255, 0.95) 62626;

    padding: 5px 15px;
}

.s-story a {
    color: #ced2d9;
    font-weight: 600;
    overflow: auto;
    margin: 3px 0;
    display: flex;
    position: relative;
    align-items: flex-start;
}

.s-story img {
    float: left;
    width: 90px;
    margin-right: 20px;
}

.s-story p {
    font-size: 11px;
    color: #000;
    margin: 0;
}

.s-story-post a:hover {
    color: #ae0a0b;
}

.s-story-post {
    /* padding: 1px 15px; */
    padding-left: 6px;
    background-color: #f7f7f7;
}

.s-story-post a {
    color: #333333;
    font-weight: 600;
    overflow: auto;
    margin: 20px 0;
    display: flex;
    position: relative;
    align-items: center;
}

.s-story-post img {
    float: left;
    width: 76px;
    margin-right: 20px;
}

.s-story-post p {
    font-size: 15px;
    margin: 0;
}

a.title {
    background: #ae0a0b;
    color: #fff;
    font-weight: 600;
    display: block;
    padding: 6px 20px;
    height: 41px;
    font-size: 20px;
    margin-bottom: 1px;
}

a.title i {
    float: right;
    line-height: normal;
    transform: translateY(4px);
}

.insta {
    display: flex;
    margin-bottom: 10px;
}

.insta a {
    display: block;
    color: #000;
    margin: 0 5px;
    margin-bottom: 15px;
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.insta a p {
    margin: 0;
    margin-top: 4px;
    color: #ae0a0b;
}

.insta a img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    border: 3px solid #ae0a0b;
    display: block;
    margin: auto;
}

.ad-side {
    /* margin-top: 20px; */
    overflow: hidden;
}

.ad-side p {
    text-align: center;
    margin-bottom: 5px;
    color: #ccc;
}

.gujarat {
    padding: 25px 0;
}

.gujarat .col-md-5 {
    padding-right: 0;
}

.gujarat .shared {
    margin-top: 10px;
}

.videos {
    padding: 15px 0;
}

.videos a:not(.readmore) {
    display: block;
    cursor: pointer;
    position: relative;
}

.videos .relateds a {
    margin-bottom: 15px;
}

.videos .relateds span {
    cursor: pointer;
}

.videos .relateds a:last-child {
    margin: 0;
}

.videos .border-video .iframePlay

/* ,.videos a img  */
    {
    display: block;
    box-shadow: 0 0 0 .75px #ae0a0b !important;
}

.gujarat.bg-gray .row .related-blogs:first-child {
    border-top: 1px solid #ccc;
}

.gujarat .row .related-blogs:first-child {
    border-top: 0;
}

span.astro-active-tab-read-more a.readmore-link {
    font-size: 13px;
    display: none;
}

span.astro-active-tab-read-more a.readmore-link img {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    transform: translateY(-1px);
}

.national {
    padding: 15px 0;
}

.national .col-md-5 {
    padding-right: 0;
}

.national .col-md-4 {
    padding-left: 0;
}

.trending {
    padding: 15px 0;
}

.trending .blog-details {
    padding: 10px 5px;
}

.world {
    padding: 15px 0;
}

.world .col-md-4 {
    padding-left: 0;
    padding-right: 0;
}

.world .col-md-4:first-child {
    padding-left: 15px;
}

.world .col-md-4:last-child {
    padding-right: 15px;
}

.ad-2 {
    z-index: -1;
}

.ad-2 a {
    display: block;
}

.ad-2 img {
    width: 100%;
    display: block;
}

.ad-3 {
    background: #f0f0f0;
}

.ad-3 a {
    display: block;
}

.ad-3 img {
    width: 100%;
    display: block;
}

.astro {
    padding: 15px 0;
}

.astro ul {
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    overflow-y: clip;
    scroll-behavior: smooth;
    /* margin-bottom: 25px; */
}

.astro li {
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
}

.astro li a {
    color: #000;
}

.astro li img {
    width: 70px !important;
    height: auto !important;
}

.astro li h5 {
    font-size: 21px;
    margin: 0;
}

.astro li h6 {
    font-size: 12px;
    margin: 0;
    margin-top: 4px;
}

.astro p.astro-carousel-title.margin-button {
    margin-bottom: 5px !important;
}

.astro p {
    margin-bottom: 0;
}

.astro p b {
    display: block;
}

.readmore {
    float: right;
    color: #ae0a0b !important;
    font-weight: 600;
    /* transform: translateY(5px); */
    transform: translateY(6px);
}

.s-story.p-0 hr:last-child,
.scroll.s-story hr:last-child {
    display: none;
}

.s-story .color-red,
.blog-details-national span .color-red,
.blog-details-national-new span .color-red,
.blog-details span .color-red {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.post-content * {
    word-break: break-word;
    line-height: normal !important;
}

#ubvideoFrame {
    max-width: 100%;
}

.post-content td,
.post-content th {
    border: 1px solid #ccc;
    padding: 5px 10px;
}

.post-content table {
    margin-bottom: 15px;
}

.post-content iframe {
    position: relative !important;
    display: block;
    border-radius: 6px;
    overflow: hidden;
    margin: 10px auto;
    max-width: 100%;
}

blockquote.instagram-media-registered {
    background: #eee;
    margin: 0;
    padding: 15px;
}

.post-content img {
    height: auto;
    margin-bottom: 10px;
}

span.copyMsg {
    position: fixed;
    left: 50%;
    bottom: 10px;
    background: #67aa51;
    color: #fff;
    transform: translateX(-50%);
    padding: 6px 20px;
    border-radius: 5px;
    /* font-weight: 600; */
    font-size: 15px;
    z-index: 99999;
}

#ZoomModal .closebtn-zoom-epaper {
    background: #ae0a0b;
    color: #fff;
    line-height: 40px;
}

img.epaper-zoom-modal-page.minimize {
    width: auto;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}

.zoombtn-inner-epaper {
    color: #fff;
    left: 10px;
    cursor: pointer;
    top: 10px;
    position: fixed;
    background: #ae0a0b;
    padding: 7px 15px;
    border-radius: 4px;
}

.zoombtn-inner-epaper i {
    margin-right: 10px;
}

.videoSidebar .closebtn-zoom-epaper {
    position: fixed;
    top: 10px;
    padding: 2px 11px;
    border-radius: 50px;
    right: 25px;
    font-size: 25px;
    background-color: #f0f0f0;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.epaper-zoom-modal-page {
    width: max-content;
    max-width: max-content;
    padding: 25px 15px;
}

.epaperZoomer {
    text-align: center;
    overflow: auto;
    height: 100vh;
    width: 100%;
}

.scrollValue {
    min-height: 100px;
}

.gallery-component p.ellipsis,
.lifestyle p.ellipsis,
.blogs p.ellipsis,
.trendingheight .blog-details p {
    min-height: 43.2px;
}


.minimize.epaperZoomer {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.removePaddingHover .img-in-txt.topmargins {
    margin-top: 2px !important;
}

div#ZoomModal {
    overflow: hidden;
    transition: all 0.2s;
    padding: 0 !important;
}

.img-in-txt.leftScrollEpaper {
    margin-bottom: 15px !important;
}

.viewClipped .col-md-12 {
    margin-bottom: 25px;
    text-align: center;
}

h1.nameEpaper {
    text-align: center;
    margin: 0;
    margin-top: 20px;
}

.redirect-home {
    color: #fff !important;
    background: #ae0a0b !important;
    font-size: 14px;
    box-shadow: 0 2px 4px #ccc;
    padding: 10px 15px;
}

.right-top-post .related-blogs:first-child {
    margin-top: -5px;
    padding-top: 0 !important;
}

.isCategoryPage .blog-time {
    white-space: nowrap;
}

.isCategoryPage .right-top-post a.related-blogs {
    /* padding: 11px 0; */
    padding: 16px 0;
}

.digi-clock {
    background-color: #ae0a0b;
    color: #fff;
    padding: 1px 5px;
    border-radius: 2px;
}

.active-menu {
    background-color: #be000c;
}

.astro-carousel-title-active {
    color: #ae0a0b !important;
}

.menu-hover:hover {
    background-color: #e4000d;
}

.menu-page-link-hover {
    color: #ae0a0b;
    position: relative;
    left: 0%;
}

.menu-page-link-hover:hover {
    color: #ae0a0b;
}

.menu-page-link-hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0%;
    width: 0%;
    height: 1px;
    border-radius: 4px;
    background-color: #ae0a0b;
    transition: 0.3s;
}

.menu-page-link-hover:hover::after {
    width: 100%;
}

.sandesh-nav-logo {
    display: none;
}

.navbar-toggler {
    background: none;
    border: none;
}

.navbar-toggler:active,
.navbar-toggler:focus {
    outline: 0;
}

.e-paper-header {
    display: none !important;
}

.navbar-toggler .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
}

.navbar-light .navbar-toggler .icon-bar {
    background: #fff;
}

.navbar-dark .navbar-toggler .icon-bar {
    background: #ffffff;
}

.navbar-toggler .icon-bar:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.navbar-toggler .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .icon-bar:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
    transform: rotate(0);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
    transform: rotate(0);
}

.remove-border:focus {
    outline: none;
    box-shadow: none;
}

.navbar-light .navbar-nav .nav-link {
    /* color: #000000 !important; */
    font-size: 16px;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
}

.dropdown-toggle::after {
    display: none;
}

.gallery-space-right {
    margin-right: 30px;
}

.removePaddingLeft {
    /* padding: 0; */
    padding-left: 15px;
}

.img-16-9-blogs {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    margin: 0;
}

.removePaddingRight {
    padding-left: 2px;
}

.dropdown-menu {
    background-color: #ae0a0b !important;
    border: none !important;
    /* border-radius: 3px !important; */
    margin: 0 !important;
}

.dropdown-item {
    background-color: #b80009 !important;
    padding: 0%;
}

.dropdown-item:hover {
    background-color: #aa000b !important;
    color: #fff;
}

.dropdown-menu a:hover {
    background-color: transparent;
}

/* .dropdown:hover .dropdown-menu {
    display: block;
  } */

.react-multiple-carousel__arrow--right {
    z-index: 5 !important;
}

.react-multiple-carousel__arrow--left {
    z-index: 5 !important;
}

/* .video-iframe-main {
    width: 100%;
    height: 430px;
  }
  
  .video-iframe {
    width: 100%;
    height: 205px;
  } */

/* .footer-logo {
    display: flex;
    justify-content: center;
    max-height: 70px;
    align-items: center;
  } */

.text-border-left {
    color: #000000;
    /* border-left: 2px solid gray; */
    width: 100%;
    /* margin-left: 15px; */
}

ul.post-ul-main {
    list-style-type: square;
    line-height: 1;
}

.post-ul-main {
    color: #000000;
    font-size: 18px;
}

.post-ul-main li {
    padding: 5px 0;
    list-style-type: inherit;
    margin-left: 25px;
}

.social-post {
    color: #333333 !important;
}

.social-share-text {
    background-color: #e6e6e6;
    font-size: 18px;
    padding: 5px 10px;
}

.social-icon {
    background-color: #e6e6e6;
    font-size: 20px;
    padding: 4px 10px;
}

.social-icon a {
    color: #000000 !important;
}

.social-icon a:hover {
    color: #ae0a0b !important;
}

.comment-section-post {
    padding: 0 10px;
}

.comment-section-post b {
    font-size: 16px;
}

.icon-bell a {
    color: #969696 !important;
    font-size: 20px;
}

.colorspan-top-post {
    color: #fff !important;
    left: 0px;
    max-height: 40px;
    top: 0px;
    position: absolute;
    padding: 0 7px;
    font-weight: 600;
    font-size: 12px;
    border-left: 5px solid #ae0a0b;
}

.related_articles .img-post {
    overflow: hidden;
    border-radius: 4px;
}

.img-post {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

.img-post img {
    vertical-align: middle;
}

.img-post .content {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    /* Fallback color */
    background: rgba(0, 0, 0, 0.5);
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    padding: 5px 6px;
}

.border-none {
    border: none !important;
}

.removePaddingHover .img-in-txt {
    padding: 0;
    cursor: pointer;
}

.img-in-txt {
    position: relative;
    /* max-width: 800px; */
    background-color: #eaeaea;
    margin: 0 !important;
    padding: 5px;
    transition: all 0.3s;
    box-shadow: 0 0 0 #ccc;
}

.img-in-txt img {
    vertical-align: middle;
}

.img-in-txt .content {
    position: absolute;
    bottom: 0;
    /* background: rgb(0, 0, 0); */
    /* Fallback color */
    background: rgba(0, 0, 0, 0.6);
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    left: 0;
    padding: 0 15px;
}

.img-in-txt .content h6 {
    /* padding: 10px 0px; */
    margin-bottom: 0px;
}

.cap-post-head-business {
    background-color: #ae0a0b;
}

.cap-post-head-business a {
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    line-height: 1.7;
}

.epaper {
    background-color: #eaeaea;
}

.epaper-news-img {
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 30px;
    /* border: 5px solid #eaeaea; */
}

.epaper-news-img {
    /* border: 1px solid #fff; */
    background-color: #ccc;
    cursor: pointer;
    height: 275px;
}

.img-in-txt:hover {
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
}

.epaper-bottom-text {
    /* background-color: #AE0A0B; */
    color: #fff;
    padding: 0 !important;
    margin: 0 !important;
    line-height: normal;
    text-transform: uppercase;
}

.epaper-bottom-text-supplements {
    /* background-color: #AE0A0B; */
    color: #fff;
    width: 202px;
    text-align: center;
}

input[type="date"] {
    position: relative;
}

input[type="date"]:after {
    content: "\f133";
    font-family: "Font Awesome 5 Pro";
    color: #555;
    padding: 0 5px;
    display: none;
}

input[type="date"]:hover:after {
    color: #bf1400;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
    z-index: 1;
}

.dark-mode {
    filter: invert(1) hue-rotate(180deg);
}

.dark-mode img {
    filter: invert(1) hue-rotate(180deg);
}

.dark-mode iframe {
    filter: invert(1) hue-rotate(180deg);
}


.img-in-txt .content-epaper {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: rgba(164, 3, 3, 0.5);
    background: rgba(164, 3, 3, 1);
    color: #f1f1f1;
    width: 100%;
    padding: 5px 0;
    display: inline-block;
}

.left-video {
    position: absolute;
    left: 23px !important;
}

.videos-main-headline {
    position: absolute;
    left: 80px !important;
}

.left-video-space {
    position: absolute;
    left: 15px !important;
}

.padding-video-right {
    margin: 15.3px 0 !important;
    display: block !important;
    display: flex;
    justify-content: space-between;
}

.padding-video-right-main-blogs {
    margin-right: 10px;
    width: 100px;
    height: 60px;
}

.video-height {
    height: 153px;
}

.related-blogs .blog-details-national {
    padding: 0 15px;
}

.blog-details-national p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.related-blogs .blog-details-lifestyle {
    padding: 14px 15px;
}

.blog-details-lifestyle p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.dailymotion-home-page {
    width: 100%;
    height: 564px;
}

.dailymotion-home-page-main-blogs {
    width: 100%;
    height: 150px;
}

.video-inner-head-text {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
}

.related-blogs .blog-details-gallery {
    padding: 7.6px 0px;
}

.blog-details-gallery p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.related-blogs .blog-details-national-new {
    padding: 0 15px;
}

.post .post-content ul,
.post .post-content ol {
    margin-left: 25px;
}

.menu-page-fix {
    height: 45px;
    overflow: hidden;
}

/* .menu-page-fix a {
    display: none;
  } */

.col-lg-2.name {
    text-align: center;
    font-size: 17px;
    background: #AE0A0B;
    font-weight: 900;
    z-index: 99;
    padding: 5px 0px;
    height: 36px;
    margin: 5px 0;
    color: #fff;
}

.col-lg-10.headline {
    padding-left: 0;
}

.col-lg-10.headline a {
    text-align: center;
    font-size: 20px;
    padding: 8px 0px;
    font-weight: bold;
    /* display: inline; */
}

.menu a.act {
    /* background: #f0f0f0 !important; */
    /* color: #3d3d3d !important; */
    position: relative;
    width: auto;
}

.menu a.act:after {
    content: "";
    position: absolute;
    left: 6px;
    /* border: 0; */
    width: auto;
    right: 6px;
    background: #fff;
    height: 2px;
    bottom: 6px;
}

.blog-details-national-new p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

section.videos {
    background: url("../assets/bg.jpg") no-repeat center;
    background-size: cover;
}

.playicon {
    position: absolute;
    top: 50%;
    margin: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background: rgb(0, 0, 0);
    background: rgba(245, 245, 245, 0.3);
    border-radius: 50%;
    padding: 10px 15px;
}

.playicon-subhome {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    background: rgb(0, 0, 0);
    background: rgba(255, 243, 243, 0.5);
    border-radius: 50%;
    padding: 10px 15px;
}

.playicon-subhome-subcategory {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 10px;
    transform: translate(-50%, -50%);
    color: #fff !important;
    background: rgb(0, 0, 0);
    background: rgba(255, 243, 243, 0.5);
    border-radius: 50%;
    padding: 5px 10px;
}

.fixed {
    position: fixed;
    bottom: 0px;
    left: 0%;
    z-index: 999;
    background-color: #eaeaea;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    width: 100%;
}

.bg-black {
    background-color: #000000 !important;
}

.w-100vw {
    width: 100vw;
}

.comment-box .closebtn i {
    font-size: 22px;
    cursor: pointer;
}

.taboola {
    margin-bottom: 50vh !important;
}

.mb-10 {
    margin-bottom: 230px !important;
}

.mb-4-half {
    margin-bottom: 2.2rem;
}

.height-500 {
    height: 500px;
}

.bg-f7f7f7 {
    background-color: #f7f7f7;
}

.GalleryInner .gallery-head-title {
    font-size: 22px;
    font-weight: bold;
}

.GalleryInner .head-subtitle {
    font-size: 14px;
}

.GalleryInner .top-category-title {
    font-weight: bold !important;
    font-size: 20px;
}

.GalleryInner .related-gallery-title {
    font-size: 20px;
    font-weight: 500;
}

.GalleryInner .related-gallery-blog-title {
    font-size: 12px;
}

.GalleryInner .left-fixed-icon-border {
    font-size: 20px;
}

.GalleryInner .left-icon-center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.GalleryInner .left-icon-border {
    transition: 0.3s;
    width: 40px;
    height: 40px;
    border: 1px solid #979797;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.GalleryInner .left-icon-border:hover {
    color: #fff;
    cursor: pointer;
    background-color: #000;
    border: 1px solid #000;
}

.GalleryInner .left-icon-fixed {
    position: sticky !important;
    top: 100px !important;
}

.GalleryInner .left-icon-fixed-up-down {
    position: sticky !important;
    top: 300px !important;
}

.gallery-main-post-title {
    position: absolute;
    color: #fff !important;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    width: auto !important;
    bottom: 0px;
    left: 15px;
    right: 15px;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 24px;
}

.nav-tabs-astro-component .nav-link-astro-component {
    color: #222;
    padding: 10px 15px;
    font-size: 15px;
}

.border-right {
    border-right: 2px solid #000;
}

.nav-tabs-astro-component .nav-link-astro-component.active {
    color: #ae0a0b;
    font-weight: bold;
    box-shadow: inset 0px -4px 0px #ae0a0b;
}

.astro-title {
    position: relative;
    color: #ae0a0b;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 22px;
    background: #fff !important;
    z-index: 4 !important;
    padding-right: 5px;
}

.astro-desc-title {
    font-weight: 400;
}

.astro-desc-title span {
    color: #ae0a0b;
    font-weight: bold;
}

.tabpane-astrology .astro-title:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background: #ae0a0b;
    bottom: 4px;
    left: 0;
    z-index: 2 !important;
}

.tabpane-astrology .astro-carousel {
    margin: 10px 15px;
    cursor: pointer;
}

/* .tabpane-astrology .astro-carousel-img{
    border-radius: 50%;
    padding: 30px;
    background-color: #fb4035;
    width: 100%;
  } */
.astro-carousel-title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0px 0px 0px;
}

.astro-active-tab-btn {
    display: flex;
    flex-direction: column;
    width: 10%;
}

.astro-tab-button {
    color: #000;
    background-color: #fff;
    border: 1px solid #3333;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 3px;
}

.astro-tab-button:hover {
    color: #fff !important;
    background-color: #ae0a0b !important;
    border: 1px solid #ae0a0b !important;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 3px;
    position: relative;
    transition: 0.3s;
}

.astro-tab-button:hover::after {
    content: "";
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #ae0a0b !important;
    border-radius: 3px;
    position: absolute;
    right: -8px;
    top: 22%;
    transition: 0.3s;
}

.active-astro-tab:after {
    content: "";
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #ae0a0b;
    border-radius: 3px;
    position: absolute;
    right: -8px;
    top: 22%;
}

.active-astro-tab {
    color: #fff !important;
    background-color: #ae0a0b !important;
    border: 1px solid #ae0a0b !important;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 3px;
    position: relative;
}

.astro-active-tab {
    display: flex;
}

.astro-active-tab-content {
    padding: 5px 15px;
}

.font-16 {
    font-size: 16px;
}

.astro-tabs-content {
    display: flex;
    flex-direction: column;
}

.readmore-link {
    color: #ae0a0b;
    font-size: 18px;
    padding: 5px 15px;
    font-weight: bold;
}

.readmore-link:hover {
    color: #ae0a0b;
}

.guj-metro-carousel .react-multiple-carousel__arrow::before {
    font-size: 10px !important;
}

.guj-metro-carousel .react-multiple-carousel__arrow {
    min-width: 25px;
    min-height: 25px;
    border-radius: 0px;
}

.guj-metro-carousel .react-multiple-carousel__arrow--left {
    left: calc(-4% + 19px) !important;
}

.guj-metro-carousel .react-multiple-carousel__arrow--right {
    right: calc(-4% + 17px) !important;
}

.guj-metro-carousel .nav-item .nav-link {
    background: #fff;
    color: #000;
    opacity: 0.5;
}

.guj-metro-carousel .active-guj-metro-carousel {
    background: #fff !important;
    color: #ae0a0b !important;
    opacity: 1 !important;
}

.guj-metro-carousel .react-multi-carousel-item {
    width: 100% !important;
    margin: 0;
}

.react-multi-carousel-item {
    margin: 0;
}

.trc_user_exclude_btn {
    z-index: 4 !important;
}

.footer_youtube {
    margin-left: 10px;
    font-size: 24px;
}

.border-radius-50 {
    border-radius: 50%;
}

.category-guj-end-to-end {
    margin: 15.7px 0 !important;
}

.menu-page-social-icon:hover {
    color: #ae0a0b;
}

.bg-AE0A0B {
    background-color: #ae0a0b;
}

.img-width-256px {
    width: 256px;
}

.blog-detail-w-256px {
    width: 256px;
    color: #000;
}

div.scrollX {
    display: flex !important;
    width: 3000px !important;
    overflow-x: auto !important;
}

.scrollX::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.scrollX::-webkit-scrollbar {
    width: 3px;
    height: 8px;
}

.scrollX::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
    border: 1px solid #f8f9fa;
}

.font-size-18px {
    font-size: 18px !important;
}

.live-tv-text {
    padding: 4px 15px;
    border-radius: 3px;
    margin-bottom: 7px !important;
}

.float-right {
    position: relative;
    right: 0;
}

.video-time-over-image {
    position: absolute;
    left: 0;
    border-top-left-radius: 5px;
    border-left: 5px solid #ae0a0b;
    font-size: 12px;
}

section.ft_section li {
    margin-left: 13px;
}

section.ft_section li .menu-page-social-icon {
    margin-left: 0px !important;
}

.post-sharer a {
    color: #ae0a0b;
}

.post-sharer {
    margin-top: 35px;
    font-weight: 600;
    margin-bottom: 20px;
}

.post-content,
.post-sharer {
    font-size: 18px;
}

.post-content ul li {
    list-style: square !important;
}

.post-content ol li {
    list-style: decimal !important;
}

.live-blog-time {
    font-size: 12px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0;
    position: relative;
}

.live-blog-title {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 24px;
    margin-top: 5px;
    letter-spacing: 0.2px;
}

.live-blog-title img {
    margin: 15px auto !important;
    display: inline-block;
}

.live-blog-content {
    font-size: 14px;
    color: #1a1a1a;
    line-height: 20px;
}

.live-blog-read-more {
    color: #999999;
    font: 600 12px/16px Montserrat, Verdana, arial, sans-serif;
    text-transform: capitalize;
}

.live-blog {
    border: 1px solid #ececec;
    padding: 10px 20px;
    transition: 0.2s;
    margin: 6px 0;
}

.live-blog:hover .live-blog-read-more {
    color: #ae0a0b;
}

.live-blog:hover {
    background-color: #fff9f9;
}

.live-blog-time::before {
    content: "";
    width: 10px;
    height: 10px;
    background: #ae0a0b;
    border-radius: 100%;
    background-clip: padding-box;
    position: absolute;
    left: -25px;
    top: 3px;
    z-index: 1;
}

.live-blog-share-btn {
    background-color: transparent;
    border: none;
    transition: 0.2s;
}

.live-blog-share-btn:hover {
    color: #ae0a0b;
}

button div {
    padding: 0px !important;
    margin: 10px 21px 10px 10px !important;
}

section.login .socialConnectImgDiv button {
    display: flex !important;
    align-items: center;
}

.story-title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0 0 !important;
    /* color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 20px; */
    /* position: fixed; */
    /* z-index: 1;
    margin-bottom: 20px; */

}

.img-subhome-main-video {
    width: 109.5%;
}

.btn:hover {
    color: #fff;
}

.subhome-live-tv a:hover {
    color: #ae0a0b !important;
}

.video-sub iframe {
    background-color: #000;
}

.subhome-live-tv a {
    white-space: nowrap;
    /* background-color: #ae0a0b; */
    text-align: center;
    color: #ae0a0b !important;
    padding: 10px;
    border-radius: 3px;
    height: 41px;
    width: 70px;
    /* margin: 15px 0 1px 28px; */
    font-weight: bold;
}

.active-search-result-tab {
    background-color: #f2f2f2 !important;
    color: #ae0a0b !important;
    opacity: 1 !important;
}

/* .livetv { */
/* height: 250px; */
/* } */

.subhome-live-tv {
    padding: 15px 12px;
    display: flex;
    margin: 0;
    align-items: center;
}

.subhome-live-tv span {
    margin-left: 15px;
}

.active-search-result-tab {
    background-color: #f2f2f2 !important;
    color: #ae0a0b !important;
    opacity: 1 !important;
}

/* .livetv {
    height: 250px;
  } */


section.rss .rss-title {
    font-size: 32px;
    color: #666666;
}

section.rss .rss-top-box {
    padding: 20px;
    border: 1px solid #e5e5e5;
    color: #333;
}

section.rss .rss-feed-sec-title {
    padding: 25px 0;
}

section.rss .rss-feed-sec-title .row {
    margin-bottom: 25px;
}

.menu a.liveTVMenu {
    display: none;
    font-size: 18px;
    padding: 0;
    padding-right: 10px;
    transform: translateY(2px);
}

section.rss .rss-feed-sec-title span {
    font-size: 16px;
    font-weight: bold;
    display: block;
    padding: 5px 0;
}

section.rss .rss-feed a i {
    position: absolute;
    left: 0;
    top: 4px;
}

section.rss .rss-feed a {
    color: #ae0a0b;
    text-transform: capitalize;
    font-size: 14px;
    position: relative;
    display: block;
    padding-left: 20px;
    font-weight: 600;
}

section.rss .rss-feed a:hover {
    color: #ae0a0b;
}

section.rss .rss-use-box {
    background-color: #f6f6f6;
    padding: 20px;
    margin-top: 20px;
}

section.rss .rss-use-box p {
    font-weight: 400;
    font-size: 14px;
}

section.rss .rss-use-box ul li {
    font-weight: 500;
    font-size: 14px;
    list-style: square;
}

section.rss .rss-use-box ul {
    margin: 10px 0px 0px 20px;
}

section.rss .rss-disclaimer {
    margin-top: 25px;
}

section.rss .rss-disclaimer .disclaimer-title {
    color: #8f8f8f;
    font-size: 12px;
}

section.rss .rss-disclaimer p {
    color: #8f8f8f;
    font-size: 12px;
    font-weight: 400;
}

@media screen and (max-width: 576px) {

    .dailymotion-home-page {
        width: 100%;
        height: auto;
    }

    .img-subhome-main-video {
        width: 105%;
    }

    .top10-img-responsive {
        display: flex;
        flex-direction: column;
    }

    .padding-mobile {
        padding: 0;
    }

    .menu a.act {
        /* background-color: #ae0a0b !important; */
        color: #fff !important;
    }

    .menu-page {
        display: none;
    }

    .removePaddingLeft {
        padding: 0 15px 0px 15px !important;
    }

    .removePaddingRight {
        padding: 0 15px 10px 15px !important;
    }

    .insta-head-responsive {
        display: flex;
        justify-content: center !important;
        flex-direction: column;
    }

    .insta-head {
        margin-top: 0px !important;
    }

    .header .logo {
        padding: 0;
    }

    .gujarat-responsive-space-sm {
        margin-top: 20px;
    }

    .contacts {
        display: none !important;
    }

    .darkmode {
        display: none !important;
    }

    /* .signin {
      display: none !important;
    } */

    .brand {
        display: none !important;
    }

    .sandesh-nav-logo {
        display: block;
    }

    .icon-mxwidth {
        max-width: 97%;
    }

    /* .footer-bottom-responsive {
      margin-top: 110px;
    } */

    .gallery-sec .single-photo span.caption {
        width: 260px;
        left: 47%;
        transform: translateX(-50%);
    }

    .gallery-sec .single-photo img {
        width: 275px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .gujarat .col-md-4 {
        padding-left: 15px;
    }

    .footer-hr {
        margin-top: 0;
    }

    .footer-livetv {
        margin-top: 0;
    }

    .mx-width-footer {
        max-width: 70% !important;
    }

    .mobile-margin-bottom {
        margin-bottom: 15px;
    }

    .tab-bar-padding {
        padding: 5px 10px;
    }

    .menu ul,
    .menu li {
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        list-style-type: none;
        /* display: inline-block; */
    }

    .left-icon-fixed {
        display: none !important;
    }

    .GalleryInner .related-gallery-blog-title {
        font-size: 14px;
        font-weight: bold;
    }

    .astro-active-tab-btn {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .footer-bottom-responsive {
        margin-top: 110px;
    }

    .footer-bottom-responsive-socials {
        margin-top: 30px;
    }

    .blog-time {
        font-size: 12px !important;
    }

    .gujarat .story p {
        font-size: 13px !important;
    }

    .background-fff {
        background-color: #ae0a0b;
        color: #fff;
    }

    .bg-AE0A0B-color-fff {
        background-color: #ae0a0b;
        color: #fff;
    }

    .dis-block {
        display: none;
    }

    .menu .rightsite-epaper {
        position: absolute;
        top: 50px !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .menu .rightsite-epaper {
        position: absolute;
        top: 50px !important;
    }

    .menu a.act {
        /* background-color: #ae0a0b !important; */
        color: #fff !important;
    }

    .epaperPage .new_pageitem {
        padding: 7px !important;
    }

    .gujarat .story p {
        font-size: 20px !important;
    }

    .blog-time {
        font-size: 12px !important;
    }

    .removePaddingLeft {
        padding: 0 15px 0px 15px !important;
    }

    .removePaddingRight {
        padding: 0 15px 10px 15px !important;
    }

    .logo,
    .brand {
        display: none !important;
    }

    .gallery-sec .single-photo img {
        height: 420px;
        width: 70% !important;
    }

    .gujarat-responsive-space-sm {
        margin-top: 20px;
    }

    .contacts {
        display: none !important;
    }

    .darkmode {
        display: none !important;
    }

    .location {
        display: none !important;
    }

    /* .signin {
      display: none !important;
    } */

    .footer-bottom-responsive {
        margin-top: 110px;
        background-color: #000000;
        color: #fff;
    }

    .sandesh-nav-logo {
        display: block;
    }

    .gallery-sec .single-photo img {
        height: 390px;
        width: auto;
        margin: auto;
        display: block;
    }

    .gujarat .col-md-4 {
        padding-left: 15px;
    }

    .footer-hr {
        margin-top: 0;
    }

    .footer-livetv {
        margin-top: 0;
    }

    .mx-width-footer {
        max-width: 70% !important;
    }

    .menu ul,
    .menu li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        /* display: inline-block; */
    }

    .left-icon-fixed {
        display: none !important;
    }

    .astro-active-tab-btn {
        display: flex;
        flex-direction: column;
        width: 35%;
    }

    .footer-bottom-responsive-socials {
        margin-top: 30px;
    }

    .background-fff {
        background-color: #ae0a0b !important;
        color: #fff !important;
    }

    .bg-AE0A0B-color-fff {
        background-color: #ae0a0b !important;
        color: #fff !important;
    }

    .dis-block {
        display: none !important;
    }
}

.menu-page-mobile {
    display: none;
}

@media (min-width: 768px) and (max-width: 991px) {

    section.video-sub.isCategoryPage .col-md-9,
    section.video-sub.isCategoryPage .col-3,
    section.video-sub.isCategoryPage .col-md-3,
    .astrology-component .col-md-9,
    .astrology-component .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .container,
    .container-md,
    .container-sm {
        max-width: 100%;
    }

    .GalleryInner .col-md-1 {
        display: none;
    }

    .GalleryInner .col-md-6,
    .video_inner .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    section.menu-page .col-md-10 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .menu-page {
        position: unset !important;
    }

    .samgraMetro .removePaddingLeft {
        padding: 0 15px !important;
    }

    a.related-blogs .col-8,
    a.related-blogs .col-9 {
        flex-basis: 70% !important;
        max-width: 70% !important;
    }

    /* .single-blog a { */
    /* width: 100%; */
    /* } */

    section.lifestyle img {
        width: 100%;
    }

    section.menu-page .col-xl-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .related_articles .col-md-12 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    ul.sub-category-page-menu {
        width: auto !important;
    }

    .isCategoryPage .col-md-7 {
        flex: 0 0 65%;
        max-width: 65%;
    }

    .isCategoryPage .col-lg-6.col-xl-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .isCategoryPage .col-md-5 {
        flex: 0 0 35%;
        max-width: 35%;
    }

    .menu .rightsite-epaper {
        position: absolute;
        top: 50px !important;
    }

    .menu a.act {
        /* background-color: #ae0a0b !important; */
        color: #fff !important;
    }

    /* .removePaddingLeft { */
    /* padding: 0 !important; */
    /* } */

    /* .removePaddingRight { */
    /* padding: 0 !important; */
    /* } */

    .contacts {
        display: none !important;
    }

    .darkmode {
        display: none !important;
    }

    /* .location { */
    /* display: none !important; */
    /* } */

    /* .signin {
      display: none !important;
    } */

    section.world .col-md-5 .custom-card:last-child,
    section.national .col-md-5 .custom-card:last-child {
        display: none !important;
    }

    /* .removePaddingLeft { */
    /* padding: 0; */
    /* } */

    .removePaddingRight {
        padding: 0 15px !important;
    }

    .sandesh-nav-logo {
        display: block;
    }

    .brand {
        width: 180px;
        height: auto;
    }

    .header .logo .brand img {
        height: auto;
        width: auto;
        margin-top: 0px;
    }

    .contacts {
        display: none !important;
    }

    /* .insta-head-responsive {
      display: flex;
      justify-content: space-between;
    } */
    .gujarat .story p {
        font-size: 16px !important;
    }

    .insta-head {
        margin-top: 70px;
    }

    .gujarat-responsive-space {
        margin-top: 20px;
    }

    .footer-bottom-responsive {
        margin-top: 110px;
    }

    .sport .single-blog .col-md-5 {
        margin: 0 !important;
    }

    .social-icone-center-footer {
        display: block !important;
    }

    .social-icone-center-footer li {
        /* display: inline-block; */
        float: left;
        width: 33.33%;
        text-align: center;
        padding: 6px 0;
        margin: 0 !important;
    }

    body .topicon-design {
        bottom: 70px !important;
    }

    /* .gallery-sec .single-photo span.caption {
        width: 95.4% !important;
    } */

    .menu ul,
    .menu li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        /* display: inline-block; */
    }

    .related-blogs .blog-details-gallery {
        padding: 20px 15px;
    }

    .astro-active-tab-btn {
        display: flex;
        flex-direction: column;
        width: 25%;
    }

    .footer-bottom-responsive-socials {
        margin-top: 30px;
    }

    .home .story p {
        font-size: 15px;
    }

    .top-10-news-title {
        font-size: 9px;
        margin-left: 10px !important;
    }

    .background-fff {
        background-color: #ae0a0b;
        color: #fff;
    }

    .bg-AE0A0B-color-fff {
        background-color: #ae0a0b;
        color: #fff;
    }

    .dis-block {
        display: none !important;
    }
}

@media (min-width: 991px) and (max-width: 1200px) {
    section.header .logo {
        background-position: left !important;
    }

    #postHeader {
        width: 930px !important;
        top: 76px !important;
    }

    .dis-block {
        display: block !important;
    }

    .footer-bottom-responsive-socials {
        margin-top: 30px;
    }

    .footer-bottom-responsive {
        margin-top: 110px;
        background-color: #000000;
        color: #fff;
    }

    .header .logo .brand img {
        height: auto;
        width: auto;
        margin-top: 0px;
    }

    .gallery-sec .single-photo span.caption {
        width: 94.9%;
    }

    .navbar {
        padding: 0;
    }

    .related-blogs .blog-details-gallery {
        padding: 0px;
    }

    .astro-active-tab-btn {
        display: flex;
        flex-direction: column;
        width: 15%;
    }

    .bg-AE0A0B-color-fff,
    .bg-AE0A0B-color-fff a {
        background-color: #ae0a0b !important;
        color: #fff !important;
    }

    .colors-background-fff-000 a,
    .colors-background-fff-000 li ul {
        background-color: #fff;
        color: #000 !important;
    }

    .background-fff {
        background-color: #fff;
    }
}

@media screen and (min-width: 1200px) {

    .icon-menu {
        display: none !important;
    }

    .qr-code-app img {
        padding: 0 16px !important;
    }
}

@media screen and (min-width: 1200px) {
    .dis-block {
        display: inline-block !important;
    }

    .icon-menu {
        display: none !important;
    }


    .header .logo .brand img {
        height: auto;
        margin-top: 0px;
        width: 62%;
    }

    .header .logo .brand {
        display: flex;
        /* justify-content: center; */
    }

    .header .logo {
        padding: 20px 0;
    }

    .gallery-sec .single-photo img {
        height: 390px;
        width: 100%;
    }

    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }

    .menu ul,
    .menu li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;
    }

    .bg-AE0A0B-color-fff,
    .bg-AE0A0B-color-fff a {
        background-color: #ae0a0b !important;
        color: #fff !important;
    }

    .colors-background-fff-000 a,
    .colors-background-fff-000 li ul {
        background-color: #fff;
        color: #000 !important;
    }

    .background-fff {
        background-color: #fff;
    }
}

.dropdown-menu li {
    position: relative;
}

.dropdown-menu .dropdown-submenu {
    display: none;
    position: relative;
    left: 100%;
    top: 0;
}

.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
    display: block;
}

section.investorrelations a:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f0c1";
    margin-right: 10px;
    transform: translateY(1px);
    display: inline-block;
}

section.investorrelations a {
    color: #ae0a0b;
    font-weight: 600;
    display: block;
    margin-bottom: 7px;
}

.megaList.row {
    margin-top: 15px;
}

.shadow {
    box-shadow: 0px 1px 4px #ccc !important;
    overflow: hidden;
}

section.login .otpbtn-confirm-pass {
    border: none;
    background: #ae0a0b;
    padding: 5px 10px;
    color: #fff;
    border-radius: 3px;
    margin-left: 23px;
}

.forgotMsg {
    justify-content: center;
    color: #be281a;
    display: block;
    text-align: center;
    padding-top: 15px;
    font-weight: 600;
}

.e-paper-header {
    font-size: 14px !important;
}



.main-blogs:hover p {
    color: #ae0a0b;
}

.ad-section {
    max-width: 100%;
    overflow: hidden;
}

.main-blogs:hover span {
    color: #ae0a0b;
}

.pading-Gujrat {
    padding: 10px 5px;
}

.world-padding {
    padding: 11.3px 5px;
}

.left-side {
    padding-left: 20px !important;
}

.colorspan {
    color: #fff !important;
    background: #ae0a0b !important;
    left: 0px !important;
    padding: 0 10px !important;
}

span.badge.colortitle {
    background: #ae0a0b !important;
}

.colortitle {
    color: #fff !important;
    background: #0d0d0d !important;
}

.video-bg-color {
    background: #141417;
}

.bg-remove {
    color: #c2c2c2 !important;
    background-color: transparent !important;
}

.colorspan-botton {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 15px;
    position: absolute;
    /* bottom: 315px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border-left: 5px solid #ae0a0b;
}

.colorspan-top {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 15px;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border-left: 5px solid #ae0a0b;
}

.nospaceing .single-blog {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.colorspan-top-trending {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 0 !important;
    top: 0;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 0;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border-left: 5px solid #ae0a0b;
}

.pading-Business {
    padding: 5.2px 5px;
}

.button-hover {
    display: inline-block;
    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, rgba(44, 44, 52, 1) 50%, #ae0a0b 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
}

.button-hover:hover {
    background-position: left;
}

.related-blogs .blog-details-Technology {
    /* padding: 10px 13px; */
    padding: 10px 7px;
    font-weight: 700;
}

.post {
    padding: 15px 0;
}

.post .story {
    display: block;
    margin-bottom: 20px;
    cursor: text !important;
}

.post .caption {
    position: relative;
    margin: 10px 0;
}

.post .caption img {
    display: block;
    width: 100%;
    min-height: 150px;
    /* background: #ccc; */
    transition: opacity 0.3s;
    /*opacity: 0.9;*/
}

.fa-size-footer {
    font-size: 20px;
}

.post .caption span {
    position: absolute;
    color: #fff;
    background: #ae0a0b;
    bottom: 2px;
    left: 2px;
    padding: 8px 15px;
    border-top-right-radius: 5px;
    font-weight: 600;
    font-size: 18px;
}

.post .story p {
    color: #000;
    /* font-size: 24px; */
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
}

p.button-hover.colorspan-top-post {
    font-size: 24px;
}

.post .substory .story p {
    font-size: 15px;
}

.post .story:hover img {
    opacity: 1;
}

.color-white {
    color: #fff !important;
}

.post-subtitle {
    color: black;
    font-size: 18px !important;
    font-weight: normal !important;
}

section.gujarat {
    transition: opacity 0.3s;
}

.gujarat {
    padding: 15px 0;
}

.gujarat .story {
    display: block;
    margin-bottom: 20px;
}

.gujarat .caption {
    position: relative;
}

.gujarat .caption img {
    display: block;
    width: 100%;
    transition: opacity 0.3s;
    /*opacity: 0.9;*/
}

.gujarat .caption span {
    position: absolute;
    color: #fff;
    background: #ae0a0b;
    bottom: 2px;
    left: 2px;
    padding: 8px 15px;
    border-top-right-radius: 5px;
    font-weight: 600;
    font-size: 18px;
}

.gujarat .story p {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    /* margin-top: 10px; */
}

.gujarat .substory .story p {
    font-size: 15px;
}

.gujarat .story:hover img {
    opacity: 1;
}

.gujarat .story:hover p {
    color: #ae0a0b;
}

.gujarat .story:hover span {
    color: #ae0a0b !important;
}

.gujarat .s-story a:hover {
    color: #fff;
}

.categoty-main-post-title {
    position: absolute;
    color: #fff !important;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    bottom: 0px;
    left: 0px;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
}

.gallery-main-post-title {
    position: absolute;
    color: #fff !important;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    width: 96.6%;
    bottom: 0px;
    left: 15px;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 24px;
}

.font-size-small {
    font-size: 14px !important;
    /* margin-top: 0 !important;x  */
}

.subpost-text-color {
    color: #ae0a0b;
    font-weight: 700;
}

.fab-size {
    padding: 22px;
    font-size: 40px;
    width: 85px;
    text-align: center;
    text-decoration: none;
    margin: 2px 2px;
}

.fab-size:hover {
    opacity: 0.7;
}

.fa-facebook-page {
    background: #3b5998;
    color: white;
}

.fa-twitter-page {
    background: #55acee;
    color: white;
}

.fa-google-page {
    background: #dd4b39;
    color: white;
}

.fa-youtube-page {
    background: #bb0000;
    color: white;
}

.fa-instagram-page {
    background: #125688;
    color: white;
}

.fa-pinterest-page {
    background: #cb2027;
    color: white;
}

.colorspan-top-post {
    color: #fff !important;
    /* height: 41px; */
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 0px;
    max-height: none;
    position: absolute;
    top: 0;
    padding: 3px 7px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border-left: 5px solid #ae0a0b;
    cursor: pointer;
    margin-top: 0 !important;
}

.colorspan-top-post-gallery {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 15px;
    position: absolute;
    top: 0;
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 24px;
    border-left: 5px solid #ae0a0b;
    cursor: pointer;
    margin-top: 0 !important;
}

.font-size-gujrat-title p {
    font-size: 24px;
}

.sub-title-gujrat {
    font-size: 16px !important;
    color: #6c6b6b !important;
    font-weight: normal !important;
}

.national .caption p {
    position: absolute;
    color: #fff;
    /* background: #AE0A0B; */
    /* bottom: 2px; */
    top: 200px;
    /* left: 2px; */
    padding: 8px 15px;
    border-top-right-radius: 5px;
    font-weight: 600;
    font-size: 18px;
}

.margin-bottom-national {
    margin-bottom: 8.5px;
}

.margin-bottom-fun {
    margin-bottom: 7px;
}

.margin-bottom-guj {
    margin-bottom: 3px;
}

.cursor-pointer {
    cursor: pointer;
}

.hr-breaking-news {
    color: gray;
}

.border-bt {
    border-top: 1px solid #727273;
}

.dropbtn {
    background-color: #ae0a0b;
    color: white;
    /* padding: 16px; */
    /* font-size: 16px; */
    border: none;
}

.dropup {
    position: relative;
    display: inline-block;
}

.dropup-content {
    display: none;
    position: absolute;
    background-color: #ccc;
    min-width: 40px;
    bottom: 20px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}

.dropup-content a {
    color: black;
    padding: 7px 13px;
    text-decoration: none;
    display: block;
    font-size: 14px;
}

.dropup-content a:hover {
    background-color: #ae0a0b;
    color: #fff;
}

.dropup:hover .dropup-content {
    display: block;
}

.dropup:hover .dropbtn {
    background-color: #ae0a0b;
}

.mx-width-footer {
    /* max-width: 33%; */
    max-width: 38%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

div.scroll {
    /* width: 110px; */
    height: 595px;
    overflow-y: auto;
}

.scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.scroll::-webkit-scrollbar {
    width: 8px;
    height: 3px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
    border: 1px solid #f8f9fa;
}

div.scroll-gujarat {
    height: 425px;
    overflow-y: auto;
}

.scroll-gujarat::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.scroll-gujarat::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.scroll-gujarat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.scroll-gujarat::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}

.gujaratsamagra {
    padding: 15px 0;
}

.video-content {
    /* position: absolute; */
    /* bottom: 0; */
    /* background: rgb(0, 0, 0); */
    /* Fallback color */
    /* background: rgba(0, 0, 0, 0.6); */
    /* Black background with 0.5 opacity */
    color: #000 !important;
    /* width: 100%; */
    /* padding: 0 15px; */
}

.nav-tabs-guj {
    flex-wrap: initial !important;
}

.nav-tabs-guj-samgra {
    flex-wrap: initial !important;
}

.nav-tabs-guj-samgra .nav-link {
    background: #f0f0f0;
    color: #000;
    opacity: 0.5;
}

.nav-tabs-guj-samgra .nav-link.active {
    background: #e9e9e9;
    color: #ae0a0b;
    opacity: 1;
}

.tab-bar-padding {
    padding: 8px 14px;
}

.tab-bar-padding-gujrat {
    padding: 8px 15px;
}

.categoty-padding {
    padding: 0 5px;
}

.topleft {
    top: 0 !important;
    left: 15px !important;
    font-size: 12px !important;
}

.marginright-video {
    margin-right: -1px;
}

.GalleryInner .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 34px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 30px;
    min-height: 30px;
    opacity: 1;
    cursor: pointer;
}

.GalleryInner .react-multiple-carousel__arrow::before {
    font-size: 10px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}

.GalleryInner .text-size {
    font-size: 25px;
}

.GalleryInner .color-size-text {
    color: #979797;
    font-size: 22px;
    float: right;
    text-decoration: underline !important;
}

.GalleryInner .text-size-footer {
    /* color:#979797; */
    font-size: 22px;
    line-height: 25.78px;
}

.GalleryInner .text-size-footer span {
    color: #979797;
    font-size: 22px;
}

.page-content {
    transition: opacity 0.3s;
    padding: 30px 0;
}

.page-content h1 {
    margin-bottom: 25px;
}

.page-content p {
    margin-bottom: 10px;
    font-size: 16px;
}

.page-content a {
    color: #ae0a0b;
}

.page-content ul {
    padding-left: 15px;
}

.page-content ul li {
    list-style-type: circle;
    font-size: 16px;
}

.tabpane-horoscope .buttom-text {
    margin: auto 0 0;
}

.tabpane-horoscope hr {
    margin-top: 5px;
}

.tabpane-horoscope .astro_title {
    letter-spacing: -0.4px;
    color: #222222;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.tabpane-horoscope .astro_date {
    letter-spacing: -0.28px;
    color: #ae0a0b;
    font-size: 14px;
    font-weight: bold;
}

.astrologyInner-top-post {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 14px;
    position: absolute;
    top: 0;
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 24px;
    border-left: 5px solid #ae0a0b;
    cursor: pointer;
    margin-top: 0 !important;
}

.astrologyInner .story p {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
}

.image-size {
    width: 100%;
}

.btn-color {
    background-color: #ae0a0b;
    color: #fff;
    font-weight: 500;
}

.result .left-fixed {
    position: sticky !important;
    top: 100px !important;
}

.margin-top {
    margin-top: 25px;
}

.icon-color-times {
    color: black;
}

/* .bg-color-camera {
    border-radius: 50%;
    background: #fff;
    width: 60px;
    padding: 5px 0 9px 12px;
  } */
.camera-size {
    width: 36px;
    left: 158px !important;
}

.right-side-border {
    display: inline-block;
    padding-left: 2px;
    padding-right: 10px;
    border-right: 2px solid #fff;
    text-align: center;
}

ul.sub-category-page-menu {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    align-items: center;
    width: 100%;

}

.sub-camera-img {
    width: 20px;
}

.bgcolor-div {
    position: absolute;
    color: #fff !important;
    /* background: rgb(0, 0, 0); */
    background: rgba(0, 0, 0, 0.6);
    width: 95.5%;
    bottom: 70px;
    left: 23px;
    padding: 5px 10px;
    border-radius: 5px;
    /* font-weight: 600; */
    /* font-size: 24px; */
    /* display: flex; */
}

.gallery-sub-post-title {
    position: absolute;
    color: #fff !important;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    width: auto !important;
    bottom: 16px;
    left: 15px;
    right: 15px;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 24px;
}

.font-size {
    font-size: 18px;
}

.gallery-component .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

/* .gallery-component .image-size {
    width: 60% !important;
  } */
.search-title-color {
    color: #000;
}

.search-result-focus:focus {
    border-color: gray;
}

.colorspan-top-gallery {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    /* left: 8px; */
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border-left: 5px solid #ae0a0b;
}

.entertainment-bgcolor-div {
    position: absolute;
    color: #fff !important;
    background: rgba(0, 0, 0, 0.6);
    width: 94.6%;
    bottom: 70px;
    left: 23px;
    padding: 5px 10px;
    border-radius: 5px;
}

.sport-bgcolor-div {
    position: absolute;
    color: #fff !important;
    background: rgba(0, 0, 0, 0.6);
    width: 42.2%;
    bottom: 0px;
    left: 15px;
    padding: 5px 10px;
    border-radius: 5px;
}

.img-size-astro {
    border-radius: 50%;
    overflow: hidden;
}

.epapermenu-right {
    position: absolute !important;
    right: 0;
}

.epapermenu-right a {
    padding-right: 0;
}

/* image in navbar */
img.sticky-header-logo {
    /* display: none; */
    position: absolute;
    width: 100px;
    height: 82px;
    left: -25px;
    z-index: 99;
    background: #fff;
    /* top: 0px; */
    border-radius: 0;
    padding: 5px 0;
    /* position: sticky !important; */
    /* top: 11px; */
    /* z-index: 9; */
}

li#sticky_menu_logo {
    display: none;
    background: #fff;
    padding: 7px 15px;
}

li#sticky_menu_logo a.act:after {
    content: "";
    display: none;
}

li#sticky_menu_logo_epaper {
    display: none;
    background: #fff;
    padding: 7px 15px;
}

li#sticky_menu_logo_epaper a.act:after {
    content: "";
    display: none;
}

img.sticky-header-logo {
    position: unset;
    left: 0;
    display: inline-block !important;
    height: 100%;
    padding: 0;
}

/* .menu-nav { */
/* margin-left: 75px !important; */
/* } */

.live-video-center {
    display: flex;
    justify-content: center;
}

.react-multi-carousel-list {
    position: unset !important;
}

.react-multiple-carousel__arrow--left {
    /* left: calc(-4% + 1px) !important; */
    left: calc(-0.5% + 1px) !important;
    min-width: 35px !important;
    min-height: 35px !important;
}

.react-multiple-carousel__arrow::before {
    font-size: 15px !important;
}

.react-multiple-carousel__arrow--right {
    /* right: calc(-4% + 1px) !important; */
    right: calc(-0.5% + 1px) !important;
    min-width: 35px !important;
    min-height: 35px !important;
}

.margin-top-videos {
    margin-top: 15px;
}

.follow-us .footer_socials a {
    margin: 0 10px;
}

div.commnet-sidebox {
    display: none;
    position: fixed;
    right: 0;
    width: 350px;
    height: 100vh;
    background: #fff;
    z-index: 9999;
    top: 0;
}

.epapermenu-right a {
    padding-right: 0;
}

span.sticky-num em {
    font-size: 14px;
    font-style: normal;
}

span.sticky-num {
    position: absolute;
    /* z-index: 9; */
    color: #fff;
    padding: 5px 10px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    top: 5px;
    left: 5px;
}

.playicon-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background: rgb(0, 0, 0);
    background: rgba(245, 245, 245, 0.3);
    border-radius: 50%;
    padding: 10px 15px;
}

.margin-bottom {
    margin-bottom: 0px !important;
}

.font-size-span {
    font-size: 15px;
}

.business-post-padding {
    padding: 8px 10px 8px 10px !important;
}

.text-white i.far.fa-clock {
    color: #fff;
}

i.far.fa-clock {
    color: #ae0a0b;
}

.margin-bottom-gujarat {
    margin-bottom: 32px;
}

.margin-bottom-gujarat-samagra {
    margin-bottom: 25px;
}

.color-grey {
    background-color: #f0f0f0 !important;
}

.margin-video-top {
    margin: 0 !important;
}

.div-height {
    height: 492px;
}

.font-size-video {
    font-size: 13px !important;
}

.scroll-astro,
.post div.scroll,
.isCategoryPage div.scroll,
.video_inner div.scroll {
    height: auto !important;
    overflow: unset !important;
}

.tabpane-astrology .react-multiple-carousel__arrow--right {
    /* right: calc(-4% + 1px) !important; */
    right: calc(-0.5% + 2px) !important;
    min-width: 35px !important;
    min-height: 35px !important;
}

.home .video-tag-line {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 15px;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    top: 5px;
    border-left: 5px solid #ae0a0b;
}

.home .subvideo-tagline {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 0;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 3px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 10px;
    top: 0px;
    border-left: 5px solid #ae0a0b;
}

/* .home .HomeSec3 .img-subhome-main-video .subvideo-tagline { */
/* top: 5px; */
/* } */

.videos .sub-video {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    /* left: 15px ; */
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    top: 0;
    border-left: 5px solid #ae0a0b;
}

.post .post-share {
    position: absolute;
    bottom: 0px;
    padding: 0 5px !important;
    right: 0px;
    border-bottom-right-radius: 5px !important;
}

.video-sub .video-tag-line {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 15px;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    top: 5px;
    border-left: 5px solid #ae0a0b;
}

.video-sub .subvideo-tagline {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 0;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 3px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 10px;
    top: 0;
    border-left: 5px solid #ae0a0b;
}

.video-sub .video-most-share {
    color: #fff !important;
    /* background: rgba(44, 44, 52, 1) !important; */
    left: 0px;
    position: absolute;
    /* bottom: 305px; */
    padding: 0 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    top: 0;
    border-left: 5px solid #ae0a0b;
}

.videos .border-video .iframePlay {
    position: relative;
    padding-bottom: 56.25%;
    box-shadow: 0 0 0 0.75px #ae0a0b;
    height: 0;
    overflow: hidden;
    border-radius: 5px;
    background: #000;
}

.videos .border-video iframe {
    position: absolute;
    left: 0;
    top: 0;
}

.videos .border-video {
    /* border: 3px solid #ae0a0b; */
    box-shadow: 0 0 0 0.75px #ae0a0b !important;
    border-radius: 5px;
}

.color-red {
    color: #ae0a0b !important;
    font-weight: 600;
}

.playicon-main {
    position: absolute;
    top: 91%;
    left: 96%;
    transform: translate(-50%, -50%);
    color: #fff;
    background: rgb(0, 0, 0);
    background: rgba(245, 245, 245, 0.3);
    border-radius: 50%;
    padding: 10px 15px;
}

.video-content-main {
    /* position: absolute; */
    /* bottom: 65px; */
    /* background: rgb(0, 0, 0); */
    /* background: rgba(0, 0, 0, 0.6); */
    /* color: #fff!important; */
    /* width: 100%; */
    /* font-weight: 700; */
    padding: 0 15px;
}

.gallery-comp-top-img {
    width: auto;
    height: 500px;
    margin: auto;
    display: flex;
    border-radius: 0;
}

.gallery-comp-sub-img {
    width: auto;
    height: 250px;
    margin: auto;
    display: flex;
    border-radius: 0;
}

.gallery-comp-sub-inner-img {
    width: auto !important;
    height: 215px;
    margin: auto;
    display: flex;
    border-radius: 0;
}

.App {
    --image-height: 150px;
    --image-width: var(--image-height);
}

.imgWrapper {
    margin-bottom: 0;
}

.img {
    height: var(--image-height);
    width: var(--image-width);
}

.imgLoader {
    /* height: 100%; */
    width: 100%;
    background-color: #cccccc;
    border-radius: 5px;
}

.imgWrapper--isLoading .img {
    display: none;
}

.imgWrapper:not(.imgWrapper--isLoading) .img {
    display: block;
}

.imgWrapper:not(.imgWrapper--isLoading) .imgLoader {
    display: none;
}

.s-story .main img {
    width: 100%;
}

.s-story Image {
    width: 90px;
}

.ellipsis {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    overflow: hidden;
    /* min-height: 46px; */
}

.ellipsis-one {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    line-height: normal;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-ten {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.menu .rightsite-epaper {
    position: absolute;
    top: 0;
    right: 15px;
}

.font-size-video {
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-bottom: 0 !important;
}

.font-size-video-title {
    font-size: 18px !important;
}

.message-font-size {
    font-size: 18px;
    text-align: center;
    padding: 25px 0;
    font-weight: 600;
}

.social-icone-center-footer {
    display: flex;
    justify-content: center;
}

section .tabpane-astrology .react-multiple-carousel__arrow {
    top: 45%;
}

.astro .react-multiple-carousel__arrow {
    top: 41%;
}

.astro .react-multiple-carousel__arrow--left {
    /* left: calc(-2% + 1px) !important; */
}

.astro .react-multiple-carousel__arrow--right {
    /* right: calc(-2% + 1px) !important; */
}

.removeTimes span.blog-time {
    display: none;
}

.removeTimes .blog-time {
    opacity: 0;
    visibility: hidden;
}

.icone-design i {
    background-color: white;
    border-radius: 50%;
    border: 1px solid grey;
    padding: 10px;
    color: #ae0a0b;
    cursor: pointer;
    width: 36px;
    height: 36px;
    text-align: center;
}

.topicon-design {
    position: fixed !important;
    bottom: 30px !important;
    right: 10px !important;
    z-index: 1000;
}

.live-blog-title * {
    margin: 0 !important;
    padding: 0 !important;
    text-indent: 0 !important;
    font-size: 16px !important;
    font-family: inherit !important;
}

.box-setin-textarea {
    position: absolute;
    bottom: 14px;
    right: 52px;
}

.playicon-video-Most-Shared {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background: rgb(0, 0, 0);
    background: rgba(245, 245, 245, 0.3);
    border-radius: 50%;
    padding: 10px 15px;
}

.social-share {
    color: #000;
}

.social-share:hover {
    background-color: #ae0a0b;
    color: #fff;
}

.menu-page-social-icons:hover {
    color: #ae0a0b;
}

.bg-AE0A0B-color-fff,
.bg-AE0A0B-color-fff a {
    background-color: #ae0a0b !important;
    color: #fff !important;
}

.colors-background-fff-000 a,
.colors-background-fff-000 li ul {
    background-color: #fff;
    color: #000 !important;
}

.background-fff {
    background-color: #fff;
}

.icone-color-white {
    color: #fff !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">" !important;
}

.breadcrumb-item.active {
    color: #ae0a0b !important;
}

.breadcrumb-bg {
    background-color: #fff !important;
}

.byline-text {
    font-size: 16px !important;
    color: gray !important;
}

.curser-pointer {
    cursor: pointer;
}

.add-fix-top {
    position: sticky;
    top: 60px;
    margin-top: 20px;
}

.s-story hr {
    margin: 10px 0 !important;
}

.game-title {
    font-size: 16px;
    font-weight: 700;
}

.margin-button {
    margin-bottom: 15px !important;
}

.mentionWrapper {
    width: 100%;
    background: transparent;
    font-size: 0.9rem;
    color: #a9b5c4;
}

.mentionWrapper .mentionWrapper__control {
    border-radius: 3px;
    border: 1px solid #505050;
    height: 137px;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input {
    border: 0;
    font-size: 0.9rem;
    overflow: hidden;
}

.mentionWrapper__suggestions {
    margin-top: 22px !important;
    margin-left: -5px;
    box-shadow: 0 2px 4px #ccc;
    padding: 2px;
    border-radius: 5px;
}

.mentionWrapper__suggestions li {
    padding: 1px 4px !important;
    color: #333 !important;
}

div[data-reply="replied"] {
    opacity: 0.5;
}

.myActiveFirstlast .firstpage,
.myActive1 .firstpage {
    display: none;
}

.myActivelast .lastpage {
    display: none;
}

.menu li li a.act:after {
    content: "";
    display: none;
}

/* .home .story .caption p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  } */

.timer_ {
    display: none !important;
}

.bg-color-box {
    background-color: #ae0a0b;
    border-radius: 50%;
    border: 1px solid grey;
    padding: 10px;
    color: #fff
}

.bg-color-box:hover {
    color: #ae0a0b;
    background-color: #ebebeb;
    border-color: #adadad;
}

.download-btn {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 10px 9px;
    /* line-height: 28px; */
    display: inline-block;
    background: #1d85d8;
    border: none;
    border-radius: 3px !important;
}

.btn-close {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border: none;

    cursor: pointer;
    padding: 10px 10px 9px;
    display: inline-block;
    background: #FF5722;
    margin-left: 4px;
    border-radius: 3px !important;
}

.main-content>ul>li>ul {
    margin-left: 20px;
}

.main-content ul li a {
    color: #333;
}

.main-content>ul>li>a {
    font-weight: bold;
    font-size: 17px;
}

.tabpane-astrology .image-center-scroll {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 991px) and (max-width: 1250px) {
    .blog-time {
        white-space: nowrap;
    }

    .margin-bottom {
        margin-bottom: 80px !important;
    }

}

@media (min-width: 1348px) {
    .margin-bottom {
        margin-bottom: 0px !important;
    }
}




section.location #myInput {
    background-position: 10px 10px;
    background-repeat: no-repeat;
    background-image: url("../assets/searchicon.png");
    border: none;
    border-bottom: 1px solid #bebebe;
    width: 100%;
    padding: 6px 10px 12px 70px;
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #2b2b2b;
    font-weight: 700;
    background-size: 30px;
}

section.location :focus-visible {
    outline: none !important;
}

section.location .city {
    color: #2b2b2b;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    align-self: center;
    margin: 10px;
    border-radius: 50px;
    border: 1px solid #eaeaea;
    padding: 13px 25px;
    white-space: nowrap;
    font-weight: 700;
}

section.location .location_list {
    display: contents;
}

section.location .location_title.mt-2,
section.location .my-2 {
    margin-top: 2.5rem !important;
}

section.location a.city:hover {
    border-color: #ae0a0b;
    color: #ae0a0b;
    text-decoration: none !important;
}

section.location .search_btn {
    position: sticky;
    top: 2px;
}

.e-paper_new .img-in-txt:hover {
    box-shadow: 0 0 0 !important;
}

.e-paper_new .img-in-txt {
    border-radius: 4px;
    overflow: hidden;
}

@media (max-width: 768px) {
    /* .e-paper_new .img-in-txt .content-epaper1 {
      margin-left: 79px !important;
    } */

    .e-paper_new .city_name {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 200px;
    }

    section.location #myInput {
        border: 1px solid #bebebe !important;
    }

    section.location .location_list {
        display: block !important;
    }

    section.location .location_title.mt-2,
    section.location .my-2 {
        margin-bottom: -1.5rem !important;
        border-bottom: 1px solid #bebebe;
    }

    section.location .city {
        padding: 14px 0px !important;
        border: none !important;
    }

    section.location .search_btn {
        position: sticky;
        top: 2px;
        z-index: 1;
    }

    .new_pageitem {
        padding: 7px !important;
    }

    .new_pageitem1 {
        padding: 7px !important;
    }
}

.city_name {
    border-radius: 0.25rem;
    margin-left: -15px;
    width: 180px;
    padding: 12px 15px;
    height: 41px;
    font-size: 15px;
    margin-bottom: 0px !important;
    color: #fff;
    box-shadow: 2px 3px 9px grey;
    background-color: #ae0a0b;
}

.e-paper_new {
    text-align: center;
}

.e-paper_new .city_paper {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding-bottom: 5px !important;
    padding-top: 19px !important;
    text-align: center;
    background-color: #fff;
    margin-bottom: 30px;
    margin-top: 15px;
}

.new_content {
    max-width: 759px;
}

.new_pageitem {
    background: #ae0a0b !important;
    border-color: #ae0a0b !important;
    font-weight: 900;
}

.new_pageitem1 {
    font-weight: 700;
    color: #ae0a0b;
    border: 1px solid #ae0a0b !important;
}

.new_pageitem1:hover {
    font-weight: 700;
    color: #ae0a0b;
    border: 1px solid #ae0a0b !important;
}

.page-item.disabled .page-link.new_pageitem1 {
    border-color: #ae0a0b !important;
}

.container .epaperPage .paper_v_epaper.mt-3 {
    margin-bottom: 15px !important;
}

.epaperPage .new_zoom,
.epaperPage .city_name1 {
    padding: 10px 20px !important;
    height: auto !important;
}

.city_name1 {
    cursor: pointer;
    border-radius: 0.25rem;
    white-space: nowrap;
    width: auto;
    padding: 12px 13px;
    border: 0;
    height: 41px;
    font-size: 12px;
    margin-bottom: 15px;
    color: #fff;
    box-shadow: 2px 3px 9px grey;
    background-color: #ae0a0b;
}

.city_name1:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.input-group.date.paper_date {
    width: auto;
    margin-right: 20px;
}

input.form-control.date_input {
    margin-right: 22px;
    border-radius: 4px !important;
    box-shadow: 0px 2px 4px #ccc;
    font-weight: 600;
    /* border-radius: unset !important; */
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd;
}

.epaperPage a:hover {
    color: #ffffff;
}

.next_page {
    font-size: 14px;
    font-weight: bolder;
    color: #ae0a0b;
    background-color: #fff;
    border: 1px solid #ae0a0b;
}

.new_zoom {
    border-radius: 0.25rem;
    display: inline-block;
    padding: 13px 15px !important;
    height: 41px;
    margin-left: 15px;
    box-shadow: 2px 3px 9px grey;
    background-color: #ae0a0b;
    color: #fff !important;
    font-size: 12px;
}

.new_pagination {
    display: inline-flex;
}

.next_page :focus {
    z-index: 3;
    outline: 0;
    box-shadow: none !important;
}

.page_menu {
    height: auto;
    width: auto;
    padding: 10px 25px !important;
    font-size: 16px;
    font-weight: 500;
    border: none !important;
    background: #ae0a0b;
    color: #fff !important;
    box-shadow: 2px 3px 9px grey;
    text-align: left;
}

.page_menu:focus {
    color: #495057;
    background-color: #ae0a0b;
    border: none !important;
    outline: 0;
    box-shadow: 2px 3px 9px grey;
}

.p-story {
    background-color: #ffffff;
    padding: 24px 15px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.p-story img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.content-epaper2 {
    width: 162px !important;
}

.page_no {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.page_btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.p-story img.epaper-news-img {
    margin-bottom: 20px !important;
    height: auto;
    min-height: 100px;
}

div.scroll.p-story {
    display: block;
    width: 200px;
    height: 595px;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
}

.p-story.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #eaeaea;
    border: 1px solid #eaeaea;
}

.subcategory_image {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background: white !important;
}

.subcategory_image button.carousel-control-next {
    justify-content: flex-end;
    width: auto;
}

.subcategory_image button.carousel-control-prev {
    justify-content: flex-start;
    width: auto;
}

.subcategory_image .carousel-control-next-icon,
.carousel-control-prev-icon {
    padding: 11px;
    display: inline-block;
    background-color: #ae0a0b !important;
    width: 21px;
    height: 58px;
}

div#carouselExampleControls img {
    min-height: 300px;
    background: #eee url(../assets/sandesh.png) center no-repeat;
    background-size: contain;
}

.new1 {
    background: #fff !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.newgallery img.img-responsive {
    transition: 0.5s all ease-in-out;
}

.newgallery.single-photo span.caption {
    padding: 25px 5px !important;
    transition-duration: 0.3s;
}

/* .newgallery a:hover img.img-responsive { */
/* transform: scale(1.1); */
/* } */

.mtop__2 {
    margin-top: 2px !important;
}

.gallery-sec .single-photo span.caption {
    padding: 0 12px !important;
    z-index: 2;
    text-shadow: 0 2px 8px #000000a1;
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 89.5%;
    background: #0000;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.gallery_new {
    overflow: hidden;
    margin: 0 auto;
}

.gallery_new:hover {
    border-radius: 5px !important;
}

.gallery_new {
    border-radius: 5px !important;
}

/* .content-epaper.blog-details.content-epaper1 { */
/* display: inline-block; */
/* top: -27px !important; */
/* position: relative; */
/* } */

img.gallery-comp-top-img.new_gallery_img {
    width: 100%;
    height: 500px;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(4px) saturate(1.5);
    background-repeat: no-repeat;
    background-size: cover;
}

img.gallery-comp-top-img.new_gallery_img_sub {
    filter: drop-shadow(2px 4px 6px #0000);
}

img.gallery-comp-sub-img.img-responsive.new_gallery_img {
    width: 100%;
    height: 250px;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(4px) saturate(1.5);
    background-repeat: no-repeat;
    background-size: cover;
}

img.gallery-comp-sub-img.img-responsive.new_gallery_img_sub {
    filter: drop-shadow(2px 4px 6px #0000);
}

img.gallery-comp-sub-img.new_gallery_img {
    padding-right: 8px !important;
    padding-left: 10px;
    width: 100%;
    height: 357px;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(4px) saturate(1.5);
    background-repeat: no-repeat;
    background-size: cover;
}

img.gallery-comp-sub-img.new_gallery_img_sub {
    filter: drop-shadow(2px 4px 6px #0000);
}

.breadcrumb {
    padding-left: 2px;
    padding-bottom: 0;
}

section.ft_section .row {
    margin: 0;
}



section.result .nav-tabs {
    background: #f7f7f7;
    border-bottom: none;
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

section.result a.nav-link {
    color: #757575;
}

section.result a.search-result-bubbles {
    border-radius: 40px;
    border: 1px solid #aaa;
    color: #333;
    padding: 6px 20px;
    margin: 4px 2px;
    font-weight: 700;
}

.searchpage .guj-metro-carousel .nav-item .nav-link {
    font-weight: bold;
}

.searchpage .tab-content>.tab-pane {
    display: block;
}

section.result .nav-tabs .nav-link:focus,
section.result .nav-tabs .nav-link:hover {
    border-color: transparent;
    color: #ae0a0b;
}

section.result .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: unset;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-bottom: 3px solid #f7f7f7;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    padding: 14px 14px 12px;
}

section.result .nav-tabs .nav-item.show .nav-link,
section.result .nav-tabs .nav-link.active {
    background-color: unset;
    color: #232323;
    border-color: #3a3a3a;
    font-weight: 700;
}

section.result .tab-pane h2 {
    color: #313131;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    width: 100%;
    margin: 21px 0 18px;
}

section.result .tab-pane .list-start {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 15px;
    display: flex;
    margin: 0 0 21px 0;
    padding-bottom: 21px;
}

section.result .tab-pane .img-fluid {
    border-radius: 6px;
}

section.result .tab-pane .post-title a {
    color: #383838;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 6px;
    position: relative;
    text-decoration: none;
}

section.result .tab-pane .post-title a:hover,
section.result a:focus {
    color: #ae0a0b;
}

section.result span.blog-post-meta {
    color: #9c9c9c;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    margin: 0 0 6px 0;
    letter-spacing: 0.3px;
}

section.result .post-txt {
    color: #717171;
    font-size: 14px;
    line-height: 22px;
}

section.result .post-logo img {
    height: auto;
    width: 36px;
    padding-right: 3px;
    margin-bottom: 4px;
}

@media (max-width: 768px) {
    section.result .nav-tabs .nav-link {
        font-size: 14px;
        padding: 12px 6px 12px;
    }

    section.result .tab-pane .post-title a {
        font-weight: normal;
        font-size: 14px;
        display: -webkit-box;
        position: relative;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    section.result .list-start:nth-child(2n) .col-8:first-child {
        order: 2;
    }
}

div#footer_bottom {
    width: 100%;
}

section.ft_section .ft-sticky {
    width: 100%;
    bottom: 0;
    padding: 0 15px 0 15px;
    background-color: #eaeaea;
    /* position: fixed; */
    z-index: 999;
    box-sizing: border-box;
    transform: translateY(120%);
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

section.ft_section.ft-sticky--mid {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    display: table;
    position: relative;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section.ft_section .ft-sticky_wrap {
    width: 100%;
    display: flex;
    align-items: center;
}

section.ft_section .ft-sticky_lft {
    width: 25%;
}

.pagination .page-item.active .page-link {
    background: #ae0a0b;
    border-color: #ae0a0b;
}

.clipImages a {
    position: absolute;
    transition: all 0.3s;
    background-color: transparent;
}

.clipImages a:hover {
    background-color: #00000085;
}

.new_pagination.pagination .page-link {
    padding: 8px 10px;
}

.pagination .page-link * {
    color: #ae0a0b;
}

.pagination .page-link {
    color: #ae0a0b;
    cursor: pointer;
    padding: 11px 16px;
    font-size: 16px;
    font-weight: 600;
}

.pagination {
    margin-top: 35px;
}

.isCategoryPage.gujarat .col-xl-4 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.isCategoryPage.gujarat .story .topleft {
    left: 0 !important;
}

.isCategoryPage.gujarat .story {
    margin: 0;
    position: relative;
}

.isCategoryPage.gujarat .caption {
    margin-bottom: 0.5rem !important;
}

.isCategoryPage .pagination {
    margin: auto;
    margin-top: 35px;
    margin-bottom: 35px;
    width: fit-content;
    overflow: hidden;
}

section.ft_section .ft-sticky_lft>div {
    margin: -10px auto -10px auto;
    display: block;
    text-align: center;
    float: none;
}

section.ft_section .follow-us .footer_socials {
    margin: 5px 0;
}

section.ft_section .ft-sticky_logo {
    width: 120px;
    float: left;
}

section.ft_section .follow-us,
section.ft_section .ft-sticky_search {
    width: 100%;
    float: left;
    margin-top: 15px;
}

section.ft_section.follow-us span {
    line-height: 1;
    font-size: 13px;
    text-transform: uppercase;
    display: block;
}

section.ft_section.ft-sticky section.ft_section.footer_socials {
    font-size: 0;
    line-height: 0;
}

section.ft_section.footer_socials {
    float: left;
    clear: both;
    margin-top: 5px;
}

section.ft_section .footer_socials .footer_fb {
    background-position: -5px -104px;
}

section.ft_section .footer_socials .footer_tw {
    background-position: -36px -104px !important;
    width: 22px !important;
}

section.ft_section .footer_insta,
.footer_linkedin,
.footer_socials .footer_fb,
.footer_socials .footer_gp,
.footer_socials .footer_tw {
    display: inline-block;
    /* background-image: url(https://ws.infilon.net/sandesh/web/img/homepage_sprite_sep2017.png); */
    background-repeat: no-repeat;
    background-position: -5px -104px;
    background-size: 380px;
    height: 18px;
    width: 18px;
    margin-right: 20px;
}

section.ft_section a {
    font-size: 12px;
    text-decoration: none;
    color: #2b2b2b;
}

section.ft_section a:hover {
    color: #ae0a0b;
    text-decoration: none;
}

section.ft_section a:active {
    color: #ae0a0b;
    text-decoration: none;
}

section.ft_section .footer_insta,
.footer_linkedin,
.footer_socials .footer_fb,
.footer_socials .footer_gp,
.footer_socials .footer_tw {
    display: inline-block;
    /* background-image: url(https://ws.infilon.net/sandesh/web/img/homepage_sprite_sep2017.png); */
    background-repeat: no-repeat;
    background-position: -5px -104px;
    background-size: 380px;
    height: 18px;
    width: 18px;
    margin-right: 20px;
}

section.ft_section .footer_socials .footer_insta {
    background-position: -139px -104px;
}

section.ft_section .footer_socials .footer_linkedin {
    background-position: -172px -104px;
}

section.ft_section .footer_koo-icn {
    width: 18px;
    display: inline-block;
    position: relative;
    top: 3px;
}

section.ft_section .follow-us,
.ft-sticky_search {
    width: 100%;
    float: left;
    margin-top: 15px;
}

section.ft_section .ft-sticky_search .footer_search {
    margin-bottom: 0;
    border-bottom: 0;
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 6px;
    padding: 0 0 10px 20px;
    box-sizing: border-box;
}

section.ft_section .footer_search {
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 25px;
    margin-bottom: 45px;
    width: 100%;
}

section.ft_section .featured_cont ul,
.wid_stry ul,
form,
li,
ul {
    list-style-type: none;
}

section.ft_section .ft-sticky_search .footer_search input {
    width: calc(100% - 30px);
    font-size: 18px;
    height: 40px;
}

section.ft_section .footer_search input {
    border: none;
    float: left;
    padding: 0;
    width: calc(100% - 50px);
    height: 48px;
    font-family: Roboto, sans-serif;
    font-size: 34px;
    font-weight: 300;
    color: #2b2b2b;
    background: 0 0;
}

section.ft_section .ft-sticky_search .footer_search .footer_searchicon {
    height: 30px;
    width: 30px;
    background-position: -102px -39px;
    background-size: 200px;
    position: relative;
    top: 12px;
    opacity: 0.65;
}

section.ft_section .footer_search .footer_searchicon {
    display: inline-block;
    /* background-image: url(https://ws.infilon.net/sandesh/web/img/homepage_sprite_sep2017.png); */
    background-repeat: no-repeat;
    background-position: -263px -122px;
    height: 48px;
    width: 48px;
    margin-top: -10px;
}

section.ft_section .ft-sticky--collapse {
    width: 100%;
    /* float: left; */
    display: block;
    border-right: 1px solid #ccc;
}

section.ft_section .ft-sticky .footer_nav {
    padding-bottom: 2px;
}

section.ft_section.ft-sticky .footer_group,
section.ft_section .ft-sticky .footer_nav {
    width: 100%;
    float: left;
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 10px;
    border-bottom: none !important;
    /* border-bottom: 1px solid #d2d2d2; */
    clear: left;
}

section.ft_section.footer_group,
.footer_nav,
.footer_trending {
    width: 100%;
    float: left;
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 2px solid #e6e6e6;
}

/* section.ft_section .featured_cont ul,
  .wid_stry ul,
  form,
  li,
  ul {
      list-style-type: none;
  } */

li.more.highlighted {
    display: none;
}

li.more.highlighted a {
    background: #2b2b2b;
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 500;
}

section.ft_section .ft-sticky .footer_nav ul li {
    float: left;
    box-sizing: border-box;
    padding-right: 16px;
    /* margin-bottom: 5px; */
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
}

/* section.ft_section .featured_cont ul,
  .wid_stry ul,
  form,
  li,
  ul {
      list-style-type: none;
  } */

section.ft_section .ft-sticky .footer_nav ul li {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
}

/* section.ft_section .featured_cont ul,
  .wid_stry ul,
  form,
  li,
  ul {
      list-style-type: none;
  } */

section.ft_section body,
html {
    font-size: 15px;
    line-height: 21px;
    font-family: Roboto, sans-serif;
}

section.ft_section .ft-sticky .footer_group {
    border-bottom: none;
    padding-top: 0;
    margin-bottom: 0;
}

section.ft_section .ft-sticky .footer_group,
.ft-sticky .footer_nav {
    width: 100%;
    float: left;
    padding-top: 5px;
    padding-bottom: 5px;
    /*        margin-bottom: 10px;*/
    border-bottom: 1px solid #d2d2d2;
    clear: left;
}

section.ft_section .footer_group,
.footer_nav,
.footer_trending {
    width: 100%;
    float: left;
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 2px solid #e6e6e6;
}

section.ft_section .ft-sticky .footer_group h2.section_head {
    width: auto;
    /*        float: none;*/
}

section.ft_section .section_head {
    width: 100%;
    float: left;
}

section.ft_section .ft-sticky--collapse .section_head span {
    font-size: 12px;
    font-weight: 500;
}

section.ft_section .section_head span {
    font-size: 15px;
    font-weight: 900;
    padding: 3px 10px;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-align: center;
    border-radius: 3px;
    text-transform: uppercase;
    background: #2b2b2b !important;
    float: left;
    color: #fff;
}

section.ft_section .ft-sticky .footer_group ul {
    width: auto;
    margin: 0;
    padding: 3px 0 0 0;
}

section.ft_section .ft-sticky .footer_group ul,
.ft-sticky .footer_nav ul {
    width: 100%;
    float: none;
    padding-top: 3px;
    margin: 0 !important;
}

section.ft_section .ft-sticky .footer_group ul li {
    float: left;
    box-sizing: border-box;
    padding-right: 12px;
    /* margin-bottom: 5px; */
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
}

section.ft_section .ft-sticky .footer_copy {
    padding: 0px;
    padding-top: 0px;
    border-top: 1px solid #d2d2d2;
    text-align: center;
    /*        margin-top: 10px;*/
    padding-top: 10px;
}

section.ft_section .footer_copy {
    width: 100%;
    float: left;
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px 0;
    text-transform: uppercase;
    text-align: center;
}

section.ft_section .ft-sticky_logo .sandesh_logo {
    width: auto;
    height: 75px;
    display: block;
    margin: auto;
}

section.ft_section .footer_copy a {
    border-right: solid 1px #c3c3c3;
    display: inline-block;
    padding-right: 10px;
    margin-right: 8px;
}

section.popup .btn-primary,
section.popup .btn-primary:focus,
section.popup .btn-primary:visited,
section.popup .btn-primary:hover,
section.popup .btn-primary:active {
    color: #2b2b2b;
    background-color: #eaeaea;
    border-color: #eaeaea;
    border-radius: 40px;
    padding: 6px 25px 6px 25px;
    line-height: 20px;
}

section.popup .btn-primary:hover {
    color: #fff;
    background-color: #ae0a0b;
    border-color: #ae0a0b;
}

section.popup .modal-header {
    padding: 3px 1rem;
    display: inline-block;
}

section.popup input.form-control {
    border: none;
    font-size: 18px;
}

section.popup .has-search .form-control {
    padding-left: 0.375rem;
}

section.popup .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 4.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

section.popup .form-group.has-search {
    margin-left: -30px;
}

section.popup .form-group {
    margin-bottom: 0rem;
}

section.popup .form-control:focus {
    border-color: unset;
    box-shadow: none;
}

section.popup .modal-body h4 {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #808080;
    padding: 10px;
}

section.popup .nav-pills .nav-link {
    border-radius: 40px;
    border: 1px solid #333;
    color: #333;
    padding: 6px 20px;
    margin: 3px;
    font-weight: 700;
}

section.popup .nav-pills .nav-link.active,
section.popup .nav-pills a:hover,
section.popup .nav-pills .show>.nav-link {
    color: #ae0a0b;
    border: 1px solid #ae0a0b;
    background: transparent;
    border-radius: 40px;
    position: relative;
}

section.popup .modal-header .close {
    padding: 0px 4px;
    margin-top: -56px;
    margin-right: -24px;
    /* margin: -4rem -2rem 0rem auto; */
    background: #ae0a0b;
    border-radius: 40px;
    color: #fff;
    opacity: 1;
}

section.popup .close {
    float: right;
    font-size: 20px;
}

section.popup .scroll-body {
    max-height: 400px;
    overflow-y: auto;
}

section.popup .badge {
    line-height: 0.8;
    padding: 0.25em 0.4em;
}

section.popup .badge-primary {
    color: #fff;
    background-color: #ae0a0b;
}

section.popup .badge-pill {
    padding-right: 0.4em;
    padding-left: 0.4em;
    border-radius: 10rem;
}

section.popup .badge-close {
    /* z-index: 15;
        margin-bottom: -10px;
        left: 18px;
        top: -16px;
        position: relative; */
    position: absolute;
    right: 0;
    top: -7px;
}

div#demo span b {
    font-weight: normal;
}

div#demo * {
    font-weight: 500 !important;
}

section.menu-page-mobile a.act {
    color: #ae0a0b;
    /* font-weight: 600; */
}

.subPaper {
    padding: 15px 0;
}

.subPaperHeader h1 {
    font-size: 25px;
    margin: 0;
    font-weight: 600;
}

.subPaperHeader {
    border-bottom: 1px solid #ccc;
    margin-bottom: 25px;
    padding: 15px 0;
}

.subPaperHeader b {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 400;
    display: block;
    line-height: normal;
}

.subPaperHeader a,
.subPaperHeader button {
    color: #fff !important;
    background: #ae0a0b !important;
    font-size: 14px;
    box-shadow: 0 2px 4px #ccc;
    padding: 10px 15px;
    font-weight: 400;
}

.subPaperHeader.subPaperFooter {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 25px;
}

.subPaperBody img {
    display: inline-block;
    box-shadow: 0 0 10px #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.icon-menu-logout {
    display: none !important;
}

.innerLogoText,
.open-app-title {
    display: none !important;
}

.city_paper a {
    display: block;
}

.navbar-light .navbar-brand {
    display: none;
}

.epaper-bottom-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px !important;
}

section.profile .nav-tabs .nav-link {
    border-radius: 0px !important;
    border: none !important;
}

.signin-user-icon {
    display: none !important;
}

.dd-icon-menu-padding {
    padding: 6px 6px 10px 0px;
}

@media screen and (max-width: 991px) {
    .qr-code-app {
        display: none !important;
    }

    .dropdown-menu .dropdown-submenu {
        left: 0%;
        top: auto;
    }

    section.header .topbar {
        border-bottom: none !important;
    }

    .dd-icon-menu-padding {
        padding: 6px 30px 10px 8px;
    }

    .dropdown-menu>li:hover>.dropdown-submenu {
        display: none;
    }

    .sub-sub-menu {
        margin-left: 12px !important;
    }

    .menu .left-side i.fas {
        float: none;
        transform: rotate(90deg) !important;
    }

    .signin-user-icon {
        display: block !important;
    }

    .topbar {
        background: #ae0a0b !important;
        color: #fff !important;
    }

    .topbar a {
        color: #fff;
    }

    .icon-menu {
        display: block !important;
    }

    /* section.header .topbar {
            display: none;
        } */

    .right-top-post .related-blogs:first-child {
        margin-top: 5px;
    }

    div#footer_bottom.fixed .footer_copy {
        font-size: 10px !important;
    }

    div#footer_bottom.fixed .footer_group,
    div#footer_bottom.fixed .ft-sticky_logo,
    div#footer_bottom.fixed .follow-us {
        display: none !important;
    }

    .menu-page-fix a {
        display: block;
    }

    section.post .post-share {
        bottom: 0 !important;
    }

    .astrology-component .col-md-12.my-3.gujarat-responsive-space-sm {
        padding: 0;
    }

    .video-sub .single-blog.mx-2 {
        margin: 0 !important;
    }

    .subhome-live-tv a {
        white-space: nowrap;
    }

    .epaperPage .gujarat-responsive-space-sm {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .epaperPage .gujarat-responsive-space-sm .offset-3 {
        margin-left: 0;
    }

    section.profile .row.bb {
        display: inline-flex;
        margin: 0;
        margin-top: 20px;
        width: 100%;
    }

    .gallery_new img.img-responsive:hover {
        /* transform: translateX(-50%) !important; */
        transform: none !important;
    }

    section.video-sub .marginright-video .story {
        height: 100%;
    }

    .mostshared .img-post {
        margin-bottom: 15px;
    }

    .img-post {
        max-width: none;
    }

    section.lifestyle.bg-gray.removeTimes .gujarat-responsive-space-sm {
        margin-top: 20px;
    }

    .ellipsis-ten {
        -webkit-line-clamp: 7;
    }

    .gallery-sec .single-photo img {
        height: 425px;
        width: auto;
        margin: auto;
        display: block;
        transform: none !important;
        left: auto;
    }

    section.gallery *,
    section.gallery-component .gallery-sec *,
    section.trending * {
        /* overflow-x: hidden; */
    }

    .navbar-nav .dropdown-menu {
        opacity: 1;
        visibility: visible;
        /* display: block; */
    }

    section.profile .my-5 {
        margin-top: 0 !important;
    }

    section.profile .nav.nav-tabs {
        display: flex !important;
        float: none !important;
        padding: 0 !important;
        align-items: center;
        vertical-align: middle;
        margin: 0 15px !important;
    }

    section.profile .nav.nav-tabs li {
        width: 50%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    section.profile .col-md-10 {
        padding: 0;
    }

    .e-paper-header {
        font-weight: bold;
        display: block !important;
    }

    .navbar-light .navbar-brand {
        text-align: center;
        padding: 0;
        margin: 0;
        display: block;
    }

    .tabpane-astrology span.badge.badge-warning {
        margin-left: 15px;
    }

    .tabpane-astrology span.badge {
        margin-bottom: 0;
    }

    .new_zoom {
        /* padding: 7px 15px !important; */
        float: right;
    }

    .topbar a {
        /* padding-bottom: 0 !important; */
    }

    /* .navbar {
      padding-top: 0;
    } */

    .epaperMenuspace {
        padding: 0 15px !important;
    }

    .topbar .container {
        max-width: 100%;
    }

    .header .logo {
        display: none;
    }

    .innerLogoText b {
        display: none;
    }

    .innerLogoText {
        display: block !important;
        font-size: 10px !important;
    }

    .left-menu-mobile {
        display: flex;
        align-items: center;
        margin: 0 auto 0 0;
    }

    .video-sub .marginright-video .story {
        position: relative;
        padding-bottom: 50.25%;
        padding-top: 30px;
        height: 0;
        overflow: auto;
        display: block;
    }

    .video-sub .marginright-video .story iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .sandesh-nav-logo {
        display: inline-block;
        align-items: center;
        /* justify-content: center; */
        /* position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%); */
        /* margin: 0 auto; */
    }

    .right-menu-mobile {
        display: flex;
        align-items: center;
        margin: 0 0 0 auto;
    }

    .navbar {
        justify-content: normal !important;
    }

    .open-app-title {
        border: 1px solid #fff;
        max-height: 20px;
        border-radius: 5px;
        font-size: 5px !important;
        display: block !important;
        /* margin: 7px 0 0 0; */
        opacity: 0;
        visibility: hidden;
        padding: 1px 5px !important;
    }

    .icon-header-logout {
        display: none !important;
    }

    .icon-menu-logout {
        display: block !important;
        color: #fff;
    }

    .date-top-header {
        display: none !important;
    }


    .home {
        padding-top: 5px;
    }

    .menu a.liveTVMenu {
        display: block;
    }

    .subPaperHeader .text-right {
        text-align: center !important;
        margin-top: 10px;
    }

    .post .col-sm-6.col-md-6.col-lg-9.col-xl-9.gujarat-responsive-space-sm {
        flex: 0 0 100%;
        max-width: 100%
    }

    .isCategoryPage {
        padding: 0;
    }

    .isCategoryPage .gujarat-responsive-space-sm {
        margin-top: 0;
    }

    div#demo {
        font-size: 12px;
        display: none;
    }

    .menu-page-mobile {
        display: block;
    }

    li.recaptcha-wrapper {
        overflow: hidden;
    }

    section.login .login-main-box {
        padding: 15px;
    }

    #cityModal .modal-header form .col-1 {
        display: none;
    }

    #cityModal .modal-header .close {
        margin-top: -40px;
        margin-right: -10px !important;
        line-height: 0;
        width: 30px;
        height: 30px;
        font-weight: 100;
    }

    section.popup .nav-pills .nav-link {
        margin: 3px;
    }

    section.popup .nav-pills a:hover,
    section.popup .nav-pills a:active {
        border: 1px solid #333;
        color: #333;
    }

    #cityModal .modal-body,
    #cityModal .modal-footer {
        padding: 5px;
    }

    .gujarat .col-xl-5.col-lg-12 .row.col-12 {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
    }

    div#demo span {
        display: block;
        margin: 0 !important;
        margin-top: 2px !important;
    }

    /* div#demo span b { */
    /* display: none; */
    /* } */

    .menu-nav {
        margin-left: 0 !important;
        max-height: inherit !important;
    }

    .menu a {
        padding: 6px 5px;
    }

    .navbar-collapse {
        max-height: 75vh;
        overflow-y: auto;
        margin-top: 10px;
    }

    .menu .rightsite-epaper {
        right: 25px;
        display: none;
    }

    .navbar-collapse.collapse.show .rightsite-epaper {
        display: contents;
    }

    .home .scroll .blog-time {
        margin-top: 3px;
    }

    a.related-blogs .col-8,
    a.related-blogs .col-9 {
        flex: 0 0 calc(100% - 70px);
        max-width: calc(100% - 70px);
        margin-left: 7px;
    }

    .right-top-post a.related-blogs .col-8 {
        flex: 0 0 calc(100% - 90px);
        max-width: calc(100% - 90px);
        margin-left: 0px;
    }

    .home .HomeSec3 .col-8,
    .rightBar .scroll .col-7,
    .home .scroll .col-7 {
        flex: 0 0 calc(100% - 110px);
        max-width: calc(100% - 110px);
    }

    .rightBar .scroll,
    .home .scroll {
        height: auto;
        padding: 5px 0;
        overflow: hidden;
    }

    .menu a.act:after {
        content: "";
        opacity: 0;
    }

    .innerPageVideo .blog-time {
        white-space: normal;
    }

    .gujarat .story .blog-time,
    .blog-time {
        font-size: 11px !important;
        white-space: nowrap;
    }

    section.popup .modal-header .close {
        margin-right: -21px !important;
    }

    section.video-sub .video-s-story {
        margin-top: -1px;
    }

    .img-subhome-main-video-page .pad-0 {
        padding: 0;
        margin-left: 15px;
    }

    .s-story hr {
        /* border-top: 0; */
        border-color: #ddd;
        margin: 0 0 7px 0 !important;
    }

    /* .home .ChangeSec {
        display: grid;
    } */

    .home .ChangeSec .HomeSec1 {
        order: 2;
    }

    .right-menu-mobile {
        opacity: 0;
    }

    .home .ChangeSec .HomeSec2 {
        order: 1;
        margin-top: 0;
    }

    .home .ChangeSec .HomeSec3 {
        order: 3;
    }

    .HomeSec3 .s-story a.img-subhome-main-video {
        display: block;
    }

    .HomeSec3 * {
        overflow-x: unset;
    }

    .HomeSec3 .col-12.main {
        padding: 0;
        margin-top: 5px;
    }

    .HomeSec3 img {
        margin: 0 !important;
    }

    .HomeSec3 a.img-subhome-main-video .subvideo-tagline {
        left: 0 !important;
        top: 0 !important;
    }

    .HomeSec3 a.img-subhome-main-video .col-12.mt-2.pr-0 {
        padding-left: 0;
    }

    .HomeSec3 .row.w-100 {
        width: auto !important;
        margin: 0;
    }

    .img-subhome-main-video {
        width: 100% !important;
    }

    .gujarat ul#content,
    .gujarat .react-multi-carousel-track {
        overflow-x: auto !important;
    }

    .world .img-in-txt,
    .national .img-in-txt {
        margin-bottom: 0 !important;
    }

    .national .ad-side-head * {
        margin: auto;
    }

    .national a.related-blogs .col {
        padding: 0;
    }

    .borderVideoBottom .font-size-video {
        margin-top: 5px !important;
    }

    .borderoverlap:after,
    .borderoverlap::before {
        display: none;
    }

    .borderVideoBottom .mb-3 a {
        border-bottom: 0 !important;
    }

    .bordersLeftRight {
        margin: 0;
        width: 100%;
    }

    .video-content-main {
        padding: 0;
    }

    .font-size-video-title {
        font-size: 14px !important;
        margin-bottom: 0;
    }

    .gallery-sec .single-photo span.caption {
        /* left: 20%;
        width: 60%;
        transform: none;
        bottom: 5px;
        display: block; */
        left: 15%;
        width: 70%;
        transform: none;
        bottom: 5px;
    }

    .gallery-sec .single-photo {
        padding-right: 0 !important;
        margin: 0;
    }

    .blogs a.related-blogs .col-8 {
        flex: 0 0 calc(100% - 90px);
        max-width: calc(100% - 90px);
        margin-left: 7px;
    }

    .sport .single-blog .row.mb-5 {
        margin-bottom: 10px !important;
    }

    .sport .single-blog {
        margin-bottom: 10px !important;
    }

    .astro a.related-blogs .col {
        padding: 0;
    }

    .astro a.related-blogs .col-8 {
        flex: 0 0 calc(100% - 90px);
        max-width: calc(100% - 90px);
        margin-left: 7px;
    }

    .astro-carousel-title {
        padding: 3px 0 !important;
    }

    .astro li {
        padding: 0;
    }

    .lifestyle a.related-blogs .col {
        padding: 0;
    }

    .lifestyle a.related-blogs .col-8 {
        flex: 0 0 calc(100% - 90px);
        max-width: calc(100% - 90px);
        margin-left: 7px;
    }

    section.ft_section {
        width: 100%;
        padding: 0;
    }

    section.ft_section .ft-sticky--collapse {
        border: 0;
    }

    /* .topicon-design { */
    /* display: none !important; */
    /* } */

    section.ft_section .ft-sticky {
        padding: 0;
    }

    section.ft_section .row {
        margin: 0;
    }

    section.ft_section .col-md-12 {
        padding: 0;
    }

    .rightBar {
        padding: 0 !important;
        border-radius: 5px !important;
        overflow: hidden;
    }

    .rightBar .s-story a {
        padding: 0 10px;
    }

    .gujarat .col-md-5 {
        padding-right: 15px;
    }

    .breadcrumb {
        padding: 0;
        white-space: nowrap;
        width: 100%;
        flex-wrap: unset;
        display: inline-flex;
    }

    .keywords a {
        font-weight: 600;
    }

    .breadcrumb li {
        white-space: nowrap;
        font-weight: 600;
        display: flex;
    }

    .breadcrumbNav {
        overflow: hidden;
    }

    .breadcrumb li:last-child {
        overflow: hidden;
        padding-left: 10px !important;
        text-overflow: ellipsis;
    }

    .breadcrumb-item {
        font-size: 12px;
    }

    section.post .story-title {
        line-height: normal;
        margin-top: 5px;
        font-size: 17px;
    }

    /* p.byline-text {
        margin-top: 3px !important;
    } */

    section.post .gujarat-responsive-space-sm {
        margin-top: 0;
    }

    .byline-text {
        font-size: 12px !important;
    }

    .post-content,
    .post-sharer {
        font-size: 15px !important;
    }

    .post-sharer {

        display: none;
    }

    .post .post-content ul {
        margin-left: 15px;
    }

    .post .d-flex.justify-content-end.social-post {
        justify-content: center !important;
    }

    .post-content iframe {
        border-radius: 5px !important;
        max-width: 95% !important;
        overflow: hidden !important;
        margin: auto !important;
        margin-bottom: 15px !important;
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    section.result a.search-result-bubbles {
        font-size: 12px;
        padding: 4px 13px;
        line-height: normal;
        border-color: #ddd;
        color: #666;
        margin: 2px;
    }

    .menu a i.fas {
        transform: translateY(-1px);
    }

    .city_paper .col-md-4 {
        width: 50%;
        padding: 0 5px;
        margin-bottom: 8px !important;
        overflow: hidden;
    }

    .img-in-txt img {
        margin-bottom: 27px;
    }

    .e-paper .epaper-bottom-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block !important;
        padding: 0 10px !important;
    }

    .e-paper .epaper-bottom-text a {
        display: none;
    }

    .epaper-news-img {
        max-height: 200px;
        height: auto;
    }

    .home .caption span,
    .post .story p {
        font-size: 16px;
    }

    .social-icone-center-footer {
        margin: 0;
    }

    .pagination .page-link {
        padding: 5px 7px !important;
        font-size: 12px;
    }

    section.comment_section .comment-box.active {
        left: 0 !important;
        width: 100%;
        padding: 0;
    }

    .comment_section .closebtn {
        position: fixed;
        right: 5px;
        top: 5px;
        background: #ae0a0b;
        line-height: 30px;
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 0;
        display: block;
        border-radius: 50px;
        z-index: 99;
    }

    .comment_section .closebtn i {
        color: #fff;
    }

    section.ft_section .follow-us .footer_socials {
        padding-bottom: 15px;
    }

    .GalleryInner .container.my-4 {
        margin-top: 5px !important;
    }

    .epaperPage .scroll.p-story {
        display: none;
    }

    .epaperPage .col-md-6.mt-3.page_btn {
        display: none;
        margin-top: 15px !important;
    }

    .epaperPage a:hover {
        /* color: #ae0a0b; */
        color: #fff;
    }

    h1.nameEpaper {
        margin: 0;
        margin-bottom: 15px;
    }

    .menu-page-mobile .col-md-10 {
        margin: 0;
    }

    .menu-page-mobile .col-md-10 .col-md-10 {
        padding: 0;
    }

    .epaperPage .new_pagination {
        padding-bottom: 15px;
    }

    .epaperPage .pagination {
        margin: 0;
        justify-content: center !important;
        padding: 0 !important;
    }

    .epaperPage .col-md-7.mt-3 {
        padding-bottom: 10px;
    }

    .city_name1 {
        /* padding: 9px 13px; */
    }

    .epaperPage .col-md-7.mt-3,
    .epaperPage .col-md-5.mt-3 {
        margin: 0 !important;
    }

    .cropimage .single-blog {
        text-align: center;
        margin-bottom: 15px;
    }

    nav.bg-transperent {
        padding-left: 0;
        padding-right: 0;
    }

    .buttonGujSamagra {
        display: none !important;
    }

    .innerPageVideo a.img-subhome-main-video {
        margin-top: 10px;
    }

    .innerPageVideo a.img-subhome-main-video .row {
        margin: 0;
    }

    .innerPageVideo a.img-subhome-main-video .subvideo-tagline {
        left: 0 !important;
    }

    .innerPageVideo a.img-subhome-main-video .row .col-12 {
        padding: 0;
    }

    .innerPageVideo .col-8 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 110px);
        max-width: calc(100% - 110px);
    }

    .videoPoPup-details {
        font-size: 13px !important;
        padding: 0 10px !important;
        line-height: normal !important;
        display: block !important;
        position: fixed;
        bottom: 10px;
        width: 100%;
        left: 0;
        border-top: 1px solid #fff;
        padding-top: 10px !important;
    }

    .videoPoPup-details p {
        margin: 0;
    }

    .dailymotion-videoPoPup-page {
        border: 0 !important;
    }

    .videoPoPup-details span {
        white-space: nowrap;
        font-size: 10px;
    }

    .videoSidebar {
        padding-top: 5vh !important;
    }

    .videoSidebar .closebtn {
        right: 15px !important;
        top: 15px !important;
        line-height: 0 !important;
        text-align: center;
    }
}

.videoPoPup-details i.far.fa-clock {
    color: #fff;
    margin-right: 3px;
}


section.login .login-main-box::-webkit-scrollbar {
    display: none;
}

section.login .login-main-box {
    z-index: 99999999999999;
    transition: right .5s !important;
    background: #f7f7f7;
    width: 435px;
    height: 100%;
    min-height: 100%;
    padding: 46px 30px;
    overflow-y: auto;
    box-shadow: 0 1px 12px 0 rgb(0 0 0 / 30%);
    position: absolute;
    right: -445px;
    top: 0;
}

section.login .login-main-box.active {
    right: 0;
    height: 100vh;
    left: initial;
}

.plans .closebtn,
section.login .closebtn {
    position: absolute;
    right: 20px;
    transform: none;
    top: 20px;
    font-size: 25px;
    cursor: pointer;
}

section.login .login-main-box.active .closebtn {
    position: fixed;
}

section.login .closebtn img {
    height: 24px;
}

section.login .inn-block {
    position: relative;
    padding: 20px;
    min-height: unset;
    border: 1px solid #d9d9d9;
    height: auto;
}


section.login .socialConnectImgDiv {
    margin-bottom: 24px;
    margin-top: 24px;
}

section.login .socialConnectImgDiv button {
    border: 0;
    border-radius: 2px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 50px;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 500;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%), 0 0 2px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
}

section.login .socialConnectImgDiv .fb-btn {
    background: #2553b4;
    color: #fff;
    font-family: Roboto, sans-serif;
}

section.login .socialConnectImgDiv .fb-btn::before {
    background-position: 0 0;
    width: 14px;
    height: 29px;
    bottom: 0;
    left: 13px;
}

section.login .socialConnectImgDiv button::before {
    position: absolute;
    content: '';
    background: url('../assets/socialicon.cms') no-repeat;
    background-size: 250px;
}

section.login .login-main-box .errorMsg {
    font-size: 12px;
    line-height: 1;
    color: #be281a;
    display: none;
    margin-top: 5px;
    font-family: roboto, sans-serif;
}

section.login .socialConnectImgDiv .gplus-btn {
    background: #fff;
    color: #757575;
    margin-top: 16px;
}

section.login .socialConnectImgDiv .gplus-btn::before {
    background-position: -19px 0;
    width: 19px;
    height: 19px;
    left: 8px;
    top: 11px;
}

section.login .login-main-box .heading {
    font-size: 16px;
    line-height: 1;
    color: #000;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

section.login .login-main-box .heading.small {
    font-size: 14px;
    color: #a2a2a2;
    font-weight: normal;
}

section.login .login-main-box .heading span {
    display: inline-block;
    background: #f7f7f7;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    color: #868686;
}

section.login .login-main-box .heading:after {
    position: absolute;
    content: '';
    background: #d6d6d6;
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
}

section.login .login-main-box .form {
    margin-bottom: 20px;
}

section.login .login-main-box .form ul li {
    list-style: none;
    margin-bottom: 22px;
}

section.login .login-main-box .form ul {
    padding: 0;
}

section.login .login-main-box .input-field {
    padding-left: 23px;
    position: relative;
}

section.login .login-main-box .input-field.email::before {
    background-position: -40px 0;
    width: 15px;
    height: 11px;
    top: 10px;
}

section.login .login-main-box .input-field.password::before {
    background-position: -75px 0;
    width: 13px;
    height: 16px;
    top: 8px;
}

section.login .login-main-box .input-field.mobile-no::before {
    width: 12px;
    height: 18px;
    top: 5px;
    background-position: -90px 0;
}

section.login .login-main-box .input-field.mobile-no p {
    border-bottom: 1px solid #888;
}

section.login .login-main-box .input-field::before {
    position: absolute;
    content: '';
    background-image: url('../assets/socialicon.cms');
    background-repeat: no-repeat;
    background-size: 250px;
    left: 0;
}

section.login .login-main-box .flags p {
    white-space: nowrap;
}

section.login .login-main-box p {
    font-size: 14px;
    line-height: 18px;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
}

section.login .login-main-box .input-field.mobile-no p .country-code {
    color: #b0b0b0;
    padding: 0 5px;
    height: 27px;
    line-height: 27px;
    vertical-align: middle;
    position: relative;
    top: -2px;
}

section.login .login-main-box .input-field.mobile-no p input {
    border-bottom: 0;
    display: inline-block;
    width: calc(100% - 47px);
}

section.login .login-main-box input[type=text],
section.login .login-main-box input[type=password],
section.login .login-main-box input[type=number] {
    height: 28px;
    border-bottom: 1px solid #888;
    background: 0;
    color: #222;
    border-radius: 0;
}

section.login .login-main-box input {
    display: block;
    width: 100%;
    line-height: normal;
    border: 0;
    font-size: 14px;
}

section.login .login-main-box .secondary-link {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #5b9ae4;
    text-transform: uppercase;
    margin-top: 5px;
}

section.login .login-main-box a {
    text-decoration: none;
}

section.login .login-main-box .input-field.password .view-password {
    opacity: .4;
}

section.login .login-main-box .input-field.password .view-password,
section.login .login-main-box .input-field.password .hide-password {
    position: absolute;
    width: 30px;
    height: 27px;
    top: 0;
    right: 0;
    z-index: 100;
    cursor: pointer;
}

section.login .login-main-box .successMsg {
    font-size: 12px;
    line-height: 1;
    color: #43b23f;
    display: none;
    margin-top: 5px;
}

section.login .login-main-box .regenerate-otp,
section.login .login-main-box .subtext {
    display: inline-block;
    color: #b0b0b0;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 12px;
}

section.login .forget-password {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
    text-decoration: underline !important;
}

section.login #sso-pwdDiv,
section.login #sso-otpLoginDiv,
section.login #changeEmailIdDiv {
    display: none;
}

section.login .login-main-box .form #sso-signInButtonDiv input,
section.login .login-main-box input[type=submit]#sso-verify-btn {
    color: #fff;
}

section.login .login-main-box input:disabled {
    opacity: .4;
}

section.login .login-main-box input[type=button],
section.login .login-main-box input[type=submit],
section.login #consentAcceptButton {
    background: #be281a;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%), 0 0 2px 0 rgb(0 0 0 / 12%);
    border: 0;
    height: 40px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

section.login .powered-by {
    width: 90%;
    text-align: center;
    margin: 50px auto 0;
}

section.login .powered-by img {
    width: 220px;
}

section.login .powered-by:before {
    content: "Powered by";
    text-transform: uppercase;
    color: #868686;
    font-size: 12px;
    display: block;
    margin: 0 0 10px 0;
}

section.login .info_txt {
    font-size: 12px;
    line-height: 16px;
    color: #595959;
    text-align: left;
    padding: 12px 24px 12px 20px;
    border-top: 1px solid #dedede;
    margin: 0 0 0 -20px;
    position: absolute;
    bottom: 0;
}

.otpfiled:before {
    content: "\f7cd" !important;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: #ccc;
    top: 5px;
}

section.login .login-main-box .checkbox {
    display: block;
    margin: 0 0 12px;
}

section.login .login-main-box .checkbox input[type="checkbox"] {
    /* display: none; */
    width: 15px;
    height: 15px;
    top: 4px;
    transform: scale(1.2);
    left: 9px;
}

section.login .login-main-box .checkbox label {
    display: inline-block;
    font-size: 14px;
    /* padding-left: 23px; */
    cursor: pointer;
    vertical-align: middle;
    font-weight: normal;
    line-height: 20px;
    color: #333;
    position: relative;
}

section.login .login-main-box .checkbox input[type="checkbox"]:checked+label:before {
    background: #3696de;
    border-color: #3696de;
}

section.login .login-main-box .checkbox label a,
.consentItemsList .checkbox label a {
    color: #5b9ae4;
}

section.login .login-main-box .mandatory-field {
    font-size: 24px;
    line-height: 12px;
    color: #c33;
    display: inline-block;
    vertical-align: bottom;
}

section.login .login-main-box .checkbox input[type="checkbox"]:checked+label:after {
    position: absolute;
    content: '';
    opacity: 0;
    border: solid #fff;
    width: 5px;
    height: 10px;
    left: 5px;
    top: 2px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

section.login .signbtn {
    background: #AE0A0B;
    border-color: #AE0A0B;
}

section.login button.btn.btn-primary.signbtn:hover {
    background: #AE0A0B !important;
    border-color: #AE0A0B !important;
}

section.login .btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgb(203 55 37);
}

section.login .profile-section input {
    border: 1px solid #ced4da !important;
}

section.login .profile_change {
    background: #AE0A0B;
    border-color: #AE0A0B;

}

section.login .profile-section input[type=text],
section.login .login-main-box input[type=password],
section.login .login-main-box input[type=number] {
    height: 28px;
    border-bottom: 1px solid #888;
    background: 0;
    color: #222;
    border-radius: 3px;
}

section.login .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(203 55 37 / 32%) !important;
}

section.login .btn-link {
    font-weight: 600 !important;
    color: #AE0A0B !important;

}

section.login .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #AE0A0B !important;
    border-color: #AE0A0B;
}

section.login .btn-link:hover {
    color: #AE0A0B;
    text-decoration: none !important;
}

section.login .btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
    text-decoration: none !important;
}

section.login .form-control:focus {

    border-color: #AE0A0B !important;

}

section.login .profile {
    background: #AE0A0B;
    display: none;
    border-color: #AE0A0B;
    /* display: block;
   margin-left: auto;
   margin-right: auto; */
    margin-bottom: 19px;
}

section.login .profile-section ul li input {
    display: inline-block !important;
    width: auto !important;

}

section.login ul {
    list-style: none;
}

section.login .star {
    color: #AE0A0B;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    section.login .login-main-box.active {
        width: 100%;
    }
}




.videoSidebar {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: all 0.5s;
    padding-top: 60px;
    z-index: 100000000;
    opacity: 0;
    visibility: hidden;
}

.videoSidebar.active-modal {
    opacity: 1;
    visibility: visible;
}

.videoSidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.videoSidebar a:hover {
    color: #f1f1f1;
}

.videoSidebar .closebtn {
    position: absolute;
    top: 10px;
    padding: 8px 11px 0 10px;
    border-radius: 50%;
    right: 25px;
    line-height: 0;
    font-size: 25px;
    background-color: #e4e6eb;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.closebtn:hover {
    color: #111;
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}

.openbtn:hover {
    background-color: #444;
}

.dailymotion-videoPoPup-page {
    width: 100%;
    /* height: 80vh; */
    height: 80vh;
    padding-top: 5px;
    padding-bottom: 15px;
    /* border-bottom: 1px solid white; */
}

.videoPoPup-details {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    overflow: hidden;
}

@media screen and (max-height: 450px) {
    .videoSidebar {
        padding-top: 15px;
    }

    .videoSidebar a {
        font-size: 18px;
    }
}



section.profile .h5,
h5 {
    font-size: 15px;
    font-weight: bold;
}

section.profile .color {
    background-color: #fff;
}

section.profile .BlueButton:hover {
    background: #AE0A0B;
}

section.profile .BlueButton {
    cursor: pointer;
    padding: 11px 0;
    color: #fff;
    outline: none;
    background: #AE0A0B;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border: none;
    float: left;
}

section.profile .mainhd {
    border-bottom: solid 1px #e7e7e7;
    line-height: 24px;
    height: 12px;
    font-size: 20px;
    margin: 0px 0 30px 0px;
    color: #333;
    text-align: center;
}

section.profile .form-control {
    font-weight: 600;
    color: #222;
}

section.profile .mainhd span {
    display: inline-block;
    padding: 0 15px;
    color: #000;
    font-weight: 600;
}

section.profile .nav.nav-tabs .nav-link {
    font-weight: 700;
    text-align: center;
}

.cancel-subscription {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cancel-subscription span {
    color: #222;
    width: 250px;
    display: block;
    font-weight: 500;
}

.cancel-subscription button {
    background: #AE0A0B !important;
    font-weight: 600;
    border: 0;
}

.cancel-subscription p {
    font-size: 17px;
    margin: 0;
    font-weight: 600;
    color: #AE0A0B;
    margin-top: 4px;
}

.cancel-subscription h3 {
    margin-bottom: 15px;
}

.subscription-history {
    margin-top: 25px;
}

.payment-package {
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 2px 4px #ccc;
    border-radius: 4px;
    margin-top: 15px;
    padding: 10px;
}

.payments p.alert-msg {
    font-size: 16px;
    color: #AE0A0B;
    font-weight: 600;
}

.payment-package div {
    font-size: 18px;
    font-weight: 600;
}

.payment-package b {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #AE0A0B;
}

section.profile .nav.nav-tabs {
    float: left;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 20px;
    /* border-bottom: 0; */
    border: 0;
    /* border-right: 1px solid #ddd; */
    /* border-right: 1px solid transparent; */
    /* padding-right: 15px; */
}

section.profile .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #AE0A0B;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(231 13 18 / 15%);
}

section.profile .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: #ccc;
}

section.profile .nav-tabs .nav-item.show .nav-link,
section.profile .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #AE0A0B;
    border-color: #dee2e6 #dee2e6 #fff;
}

section.profile .nav-tabs .nav-link a:active {
    color: #fff !important;
    background-color: #AE0A0B !important;
    border-color: transparent !important;
}

section.profile .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    background: #ced4da;
    color: #333;
}

section.profile .tab-content>a:active {
    display: block;
    /*    background: #007bff;*/
    min-height: 165px;
}

section.profile .nav-tabs .nav-link a {
    color: #333 !important;
}

section.profile textarea.form-control {
    resize: none;

}

section.profile .TxtFeildBottomLeft::before {
    content: '';
    display: inline-block;
    size: 5px;
    position: relative;
    color: #AE0A0B;
    height: 5px;
    width: 5px;
    border: 5px solid;
    margin-right: 5px;
}

section.profile ::selection {
    background: #ddd;
}

section.profile .custom-control {
    position: relative;
    z-index: 1;
    display: inline-block;
    min-height: 1rem;
    padding-left: 2rem;
    /* -webkit-print-color-adjust: exact; */
    print-color-adjust: exact;
}

section.profile .custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #AE0A0B;
}

section.profile .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #AE0A0B;
    background-color: #AE0A0B;
}

section.profile .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.1rem rgb(246 173 175);
}

section.profile .custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #f6adaf;
    border-color: #f6adaf;
}

section.profile input[type="date"]::before {
    /*   color: #ffffff;*/
    content: attr(placeholder) ":";
    margin-right: 0.3em;
    margin-left: 0;
}

section.profile input[type="date"]:focus::before {
    content: " " !important;
}

.buttonGujSamagra {
    background-color: rgb(174, 10, 11, 0.5);
    color: #fff;
    min-width: 25px;
    min-height: 25px;
    border-radius: 0px;
    border: none;
    display: flex;
    align-items: center;
    transition: .3s;
    justify-content: center;
    /* background: rgba(0, 0, 0, 0.6);
    color: #fff;
    min-width: 25px;
    min-height: 25px;
    border-radius: 0px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center; */
}

.buttonGujSamagra:hover {
    background: #ae0a0b;
}

.internal {
    width: 20%;
    height: 100%;
    text-align: center;
    background: #f0f0f0;
    color: #000;
    display: inline-block;
}

.internal a {
    background-color: #fff;
    color: #000;
    opacity: 0.5;
}

.internal a.samgra-inner {
    background-color: #ddd;
    color: #000;
    opacity: 0.5;
}

.internal a.samgra-inner.active-guj-samagra-inner {
    background-color: #ddd;
    color: #ae0a0b;
    opacity: 1;
}

.samgraMetro .nav-item a {
    cursor: pointer;
}

.center {
    float: left;
    width: 100%;
    /* background: #fff; */
    color: #000;
    margin: 1px;
    overflow: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
}

.center-metro {
    float: left;
    width: 100%;
    /* background: #fff; */
    color: #000;
    margin: 1px;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
}

.center-metro::-webkit-scrollbar {
    height: 0px;
}

.plusIcon {
    color: #000;
    opacity: 0.5;
}

.active-guj-samagra {
    background: #fff !important;
    color: #AE0A0B !important;
    opacity: 1 !important;
}


.ddd:hover {
    background: #AE0A0B !important;
}

.NoEPaper {
    text-align: center;
    font-size: 25px;
    margin-top: 35px;
    color: #ae0a0b;
}

.removeInputmg input {
    margin-right: 0 !important;
}


@media(max-width:768px) {
    .cancel-subscription.pending {
        display: block;
    }

    .cancel-subscription span {
        width: 100%;
        margin-top: 25px;
    }

    /* .fixed {
        position: relative;
        bottom: 0px;
        left: 0%;
        z-index: 999;
        background-color: #eaeaea;
        box-shadow: 0 0 10px rgb(0 0 0 / 35%);
        width: 100%;
    } */

    element {
        height: 170px;
    }

    .home .story p {
        font-size: 16px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .blog-time {
        font-size: 9px;
    }

    section.ft_section .ft-sticky_wrap {
        display: inline;
    }

    section.ft_section .ft-sticky .footer_group ul li {
        padding-right: 0px;
        margin-bottom: 5px;
        font-size: 12px;
    }

    section.ft_section .ft-sticky_lft {
        width: 100%;
    }

    section.ft_section .ft-sticky .footer_nav ul li {
        font-size: 12px;
        padding-right: 0px;
    }

    .ft_section {
        padding-right: 5px;
        padding-left: 0px !important;
    }

    .xxx.col-3 {
        flex: 100%;
        max-width: 100%;
    }

    .pl-0,
    .px-0 {
        padding-left: none !important;
    }

    .pdg {
        margin-bottom: 10px;
    }

    .post-content li {
        list-style: square !important;
        margin-left: 10px;
    }

    .alignnone.size-full.wp-image-1885401 {
        height: auto;
    }

    .alignnone.size-full.wp-image-1885402 {
        height: auto;
    }

    .social-share-text {
        font-size: 17px;
    }
}

.maintenanceMessage img {
    display: inline-block;
    margin-bottom: 75px;
}

.maintenanceMessage {
    text-align: center;
    color: #ae0a0b;
    font-weight: 600;
    min-height: 70vh;
    font-size: 20px;
}

.post_height_scroll {
    min-height: 100vh;
}

.menu li.v-hidden {
    opacity: 0;
    visibility: hidden;
}

.menu li.v-hidden .fas {
    padding: 0 3px !important;
}

.cropimage .epaper-news-img {
    height: auto;
}

section.post .post-share {
    bottom: 1px;
    bottom: 0;
    border-radius: 0;
}

body .skeleton {
    width: 100%;
    height: 0;
    display: block;
}

.eskeleton {
    width: 90px;
    margin-right: 10px;
}

.skeleton img {
    width: 100% !important;
}

.skeleton,
section.isCategoryPage .megapreView .caption,
section.isCategoryPage .substory .caption,
section.post .caption,
.skeleton-sub,
/* .HomeSec3 .imgWrapper, */
.HomeSec2 .imgWrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background: #fefefe url(../assets/sandesh.png) center no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 5px;
}

.skeleton img,
section.isCategoryPage .megapreView .caption img,
section.isCategoryPage .substory .caption img,
section.post .caption img,
/* .HomeSec3 .imgWrapper img, */
.skeleton-sub img,
.HomeSec2 .imgWrapper img {
    position: absolute;
    top: 0;
    display: block;
    left: 0;
    height: 100%;
    width: 100%;
    /* bottom: 0; */
    /* right: 0; */
    text-align: center;
    margin: 0;
    float: none;
}

.mentionWrapper__suggestions {
    max-height: 110px !important;
    overflow-y: auto !important;
}

.mentionWrapper__suggestions::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.mentionWrapper__suggestions::-webkit-scrollbar {
    width: 3px;
    height: 8px;
}

.mentionWrapper__suggestions::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
    border: 1px solid #f8f9fa;
}

.post .post-content a {
    color: #ae0a0b;
}


section.menu-page a {
    text-transform: capitalize;
}

img[width="0"] {
    display: block;
}

.gallery_new img.img-responsive:hover {
    transform: scale(1.1) translateX(0);
}

.PostLoader h1,
.PostLoader .PostLoader-skeleton,
.PostLoader .byline-text,
.PostLoader .breadcrumb {
    background-color: #f1f1f1 !important;
}

.PostLoader .PostLoader-skeleton {
    min-height: 250px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 25px;
}

.PostLoader {
    padding-top: 10px !important;
    /* display: none !important; */
}

section.login .login-main-box .input-field.user::before {
    background-position: -60px 0;
    width: 15px;
    height: 16px;
    top: 6px;
}

.eyeissue {
    display: flex;
}

.margin-icone-close {
    margin-top: 2px;
}

.trending .react-multiple-carousel__arrow {
    top: 29%;
}


.custom-card a {
    background: #fff;
    display: block;
    flex-direction: row;
    color: #333333;
    padding: 5.7px 25px;
    width: 100%;
    /* padding-bottom: 8.5px; */
    border-top: 1px solid #ccc;
}

.custom-card a:hover {
    color: #ae0a0b;
}

.custom-card .float-left {
    width: 90px;
    margin-right: 25px;
}

.custom-card .blog-time {
    white-space: nowrap;
}

.custom-card .color-red {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.custom-card .ellipsis {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.custom-card img {
    min-width: 90px;
}

.custom-card.top10 a {
    padding: 0 5px;
    border: 0px solid #fff;
}

.custom-card.top10 .float-left {
    margin-right: 15px;
}

.custom-card {
    width: 100%;
}

.custom-card.posttop10 a {
    padding: 0 5px;
    border: 0px solid #fff;
    background: #f7f7f7 !important;
}

.custom-card.posttop10 .float-left {
    margin-right: 10px;
}

.skeleton-sub .button-hover {
    position: absolute;
}

.common-card-content-title {
    color: #fff !important;
    background-color: transparent !important;
}

section.sport .col-md-5 .blog-details {
    height: 100%;
}

.bg-f7f7f7 .custom-card.top10 a {
    background: #f7f7f7;
}

.skeleton-gallery {
    position: relative;
    width: 100%;
    padding-top: 0;
    background: #eeeeee url(../assets/sandesh.png) center no-repeat;
    background-size: contain;
    overflow: hidden;
    border-radius: 5px;
}

.tab-content a.main-blogs {
    width: 100%;
}

.video-s-story .custom-card a {
    background-color: #000000;
    color: #fff !important;
}

.video-s-story p,
.video-s-story span {
    color: #fff !important;
}

.lifestyle .custom-card a {
    min-height: 80px;
}

.profile_opacity {
    opacity: 0.3;
}

@media screen and (max-width: 991px) {
    section.profile {
        padding-top: 25px;
    }

    .payment-package div {
        font-size: 15px;
    }

    .custom-card a {
        padding: 8px 15px;
    }

    .custom-card .float-left {
        margin-right: 20px !important;
    }

    .mb-4-half {
        margin-bottom: 1rem;
    }

    .menu a.act {
        /* background-color: #ae0a0b !important; */
        color: #fff !important;
    }

    .menu-page {
        display: none;
    }

    .container,
    .container-sm {
        max-width: 100% !important;
    }

    .mobile-margin-bottom {
        margin-bottom: 15px;
    }

    .blogs .single-blog {
        margin-top: 15px;
    }

    .sport .single-blog .col-md-5 {
        padding: 0;
        margin-bottom: 10px;
    }

    .video_inner .gujarat-responsive-space-sm.col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    section.world .col-md-7,
    section.world .col-md-5,
    section.national .col-md-7,
    section.national .col-md-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}



body {
    font-family: 'sandesh', 'Quicksand', 'Hind Vadodara', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

b,
strong {
    font-weight: bold;
}

.menu a {
    font-size: 14px;
    font-weight: 400 !important;
}

.menu ul ul a {
    font-weight: 600 !important;
    font-size: 13px;
}

section.menu-page a {
    font-size: 15px;
}

.custom-card .ellipsis {
    font-size: 15px;
}

.s-story p {
    font-size: 13px;
}

.story-title {
    font-size: 26px;
}

.post-content,
.post-sharer {
    font-size: 19px;
}

.post .story p {
    font-weight: 400;
}

section.lifestyle .gujarat-responsive-space-sm .nav .nav-item .nav-link {
    padding: 9px 15px;
}

section.lifestyle .gujarat-responsive-space-sm .nav .nav-item .nav-link.active {
    box-shadow: 2px 3px 9px grey;
    border: none;
    margin-bottom: 6px;
}

.single-blog a.readmore {
    margin: 4px 0 0 0;
}

.video_inner .ad-side-head span.badge,
.post .ad-side-head span.badge {
    font-size: 20px;
    padding: 11px 15px;
}

.gujarat.samgraMetro .popup span.badge {
    height: auto;
}

.blog-time,
.national .content .blog-details .cap-post-head-business a.px-2,
.world .content .blog-details .cap-post-head-business a.px-2,
.sport .content .blog-details .cap-post-head-business a.px-2,
section.video-sub.isCategoryPage .videoSidebar .videoPoPup-details {
    display: none;
}

.float-left .skeleton-sub {
    margin: 8.1px 0;
}

section.video-sub .float-left .skeleton-sub {
    margin: 1px 0;
}

section.gallery-component .react-multi-carousel-list.gallery-sec {
    margin: 45px 0 0 0;
}

.react-multiple-carousel__arrow {
    top: -53px !important;
    right: 60px !important;
    left: auto !important;
    border-radius: 5px !important;
    background-color: #AE0A0B !important;
}

section.gallery-component .react-multiple-carousel__arrow {
    /* top: 5.7% !important; */
    top: 3.86% !important;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 15px !important;
}

section.gallery .react-multiple-carousel__arrow,
section.entertainment .react-multiple-carousel__arrow,
section.astro .react-multiple-carousel__arrow {
    right: 140px !important;
}

section.gallery button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
section.entertainment button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
section.astro button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 95px !important;
}

.astrology-component .react-multiple-carousel__arrow {
    top: 18px !important;
    right: 70px !important;
}

.astrology-component button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 25px !important;
}

.react-multiple-carousel__arrow:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
}

.menu-page-social-icon-whatsapp {
    font-size: 18px !important;
    color: #00a859 !important;
}

.menu-page-social-icon-facebook {
    color: #4267B2 !important;
}

.menu-page-social-icon-twitter {
    color: #00acee !important;
}

.menu-page-social-icon-instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.menu-page-social-icon-instagram-footer {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px !important;
}

.menu-page-social-icon-yt {
    color: #FF0000 !important;
}

.menu-page-social-icon-apple {
    color: #555555 !important;
}

.menu-page-social-icon-android {
    color: #669933 !important;
}

.relateds.borderVideoBottom.gujarat-responsive-space-sm {
    z-index: 4;
}

@media (min-width: 768px) and (max-width: 991px) {
    #postHeader {
        width: 97% !important;
        top: 50px !important;
    }

    section.gallery-component .react-multiple-carousel__arrow {
        /* top: 4.94% !important; */
        top: 2.66% !important;
    }
}

@media (max-width: 768px) {
    section.gallery-component .react-multiple-carousel__arrow {
        /* top: 2.22% !important; */
        top: 1.47% !important;
    }
}

@media (max-width: 991px) {
    .common-share-trend-video-head.dropleft {
        top: 5px !important;
        right: 0px !important;
    }
}

.home .share-common .dropleft {
    position: absolute;
    top: 0px;
    right: -2px;
}

.share-common .dropleft {
    position: absolute;
    top: 0px;
    right: 7px;
}

.featured.entertainment .s-story .share-common .dropleft {
    position: absolute;
    top: auto;
    bottom: 0px !important;
    right: 7px;
}

.share-common-trend .share-common-trend-video .dropleft {
    position: absolute;
    /* bottom: 0px !important;
    right: 7px; */
    bottom: 0px !important;
    right: 1px;
    /* width: 23px; */
    /* height: 300px; */
    /* z-index: 9999 !important; */
    background-color: #fff;
    overflow: unset;

}

.share-common .dropleft .dropbtn,
.share-common-trend .share-common-trend-video .dropleft .dropbtn {
    background-color: transparent;
    color: #ae0a0b;
    margin: 0 !important;
}

.share-common .dropleft .dropdown-menu,
.share-common-trend .share-common-trend-video .dropleft .dropdown-menu {
    right: 0;
    top: 20px;
    padding: 0;
    min-width: 10px;
    background: #ccc !important;
    box-shadow: 0 2px 4px #ccc;

}

.share-common .dropleft .dropdown-menu a,
.share-common-trend .share-common-trend-video .dropleft .dropdown-menu a {
    border: 0;
    margin: 0;
    padding: 2px 7px !important;
    display: block;
}

.share-common .dropleft .dropdown-menu a:hover,
.share-common-trend .share-common-trend-video .dropleft .dropdown-menu a:hover {
    color: #ae0a0b !important;
}

.share-common .dropleft:hover .dropdown-menu,
.share-common-trend .share-common-trend-video .dropleft:hover .dropdown-menu {
    display: block;
}

.share-common.custom-card .a,
.share-common-trend a.share-common-trend-video {
    position: relative;
    overflow: unset;
}

.share-common-trend a.share-common-trend-video {
    min-height: 62px;
}

.share-common a.share-common-trend-video {
    min-height: 55px;
}

.min-h-video-page a.share-common-trend-video {
    min-height: 53px;
}






.common-share-trend-video-head.dropleft {
    position: absolute;
    top: 0px;
    right: 30px;
    background: #ae0a0b;
    color: #fff !important;
    border-top-right-radius: 5px;
}

.common-share-trend-video-head.dropleft .dropbtn {
    background-color: transparent;
    color: #fff;
    margin: 0 !important;
    padding: 2px 5px;
}

.common-share-trend-video-head.dropleft .dropdown-menu {
    right: 0;
    top: 25px;
    padding: 0;
    min-width: 10px;
    background: #ccc !important;
    box-shadow: 0 2px 4px #ccc;

}

.common-share-trend-video-head.dropleft .dropdown-menu a {
    border: 0;
    margin: 0;
    padding: 2px 7px !important;
    display: block;
    color: #000 !important;
}

.common-share-trend-video-head.dropleft .dropdown-menu a:hover {
    color: #ae0a0b !important;
}

.common-share-trend-video-head.dropleft:hover .dropdown-menu {
    display: block;
}

.common-share-trend-video-head.custom-card .a {
    position: relative;
    overflow: unset;
}





.common-share-sub-story.dropleft {
    position: absolute;
    top: 0px;
    right: 15px;
    background: #ae0a0b;
    color: #fff !important;
    border-top-right-radius: 5px;
}

.common-share-sub-story.dropleft .dropbtn {
    background-color: transparent;
    color: #fff;
    margin: 0 !important;
    padding: 2px 5px;
}

.common-share-sub-story.dropleft .dropdown-menu {
    right: 0;
    top: 25px;
    padding: 0;
    min-width: 10px;
    background: #ccc !important;
    box-shadow: 0 2px 4px #ccc;

}

.common-share-sub-story.dropleft .dropdown-menu a {
    border: 0;
    margin: 0;
    padding: 2px 7px !important;
    display: block;
    color: #000 !important;
}

.common-share-sub-story.dropleft .dropdown-menu a:hover {
    color: #ae0a0b !important;
}

.common-share-sub-story.dropleft:hover .dropdown-menu {
    display: block;
}

.common-share-sub-story.custom-card .a {
    position: relative;
    overflow: unset;
}

.margin {
    /* margin-top: 45px;
    margin-bottom: 45px; */
    /* position: fixed;
    z-index: 1 */
}

#postHeader.fix-title {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s;
}

#postHeader {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 75px;
    z-index: 99;
    background-color: rgb(255 255 255 / 95%);
    width: 1336px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-bottom: 1px solid #ae0a0b;
    padding: 0 15px;
    box-shadow: 1px 1px 5px -2px gray;
    border-radius: 0 0 4px 4px;
}

#postHeader span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: rgb(174 10 11 / 10%);
    max-width: 100%;
    transition: all .1s linear;
}

@media(max-width:768px) {
    #postHeader {
        top: 49px;
        left: 0;
        width: 100%;
        height: 60px;
        border-radius: 0;
        border-bottom: 2px solid #ae0a0b;
        background: #fff;
    }
}








section.blogs .dropleft,
section.lifestyle .dropleft {
    position: absolute;
    bottom: 3px;
    right: 7px;
}

section.blogs .dropleft .dropbtn,
section.lifestyle .dropleft .dropbtn {
    background-color: transparent;
    color: #ae0a0b;
    margin: 0 !important;
}

section.blogs .dropleft .dropdown-menu,
section.lifestyle .dropleft .dropdown-menu {
    right: 0;
    top: 20px;
    padding: 0;
    min-width: 10px;
    background: #fff !important;
    box-shadow: 0 2px 4px #ccc;

}

section.blogs .dropleft .dropdown-menu a,
section.lifestyle .dropleft .dropdown-menu a {
    border: 0;
    margin: 0;
    padding: 2px 7px !important;
    display: block;
    min-height: 0 !important;
}

section.blogs .dropleft .dropdown-menu a:hover,
section.lifestyle .dropleft .dropdown-menu a:hover {
    color: #ae0a0b !important;
}

section.blogs .dropleft:hover .dropdown-menu,
section.lifestyle .dropleft:hover .dropdown-menu {
    display: block;
}

section.blogs a.share-blog,
section.lifestyle a.share-blog {
    position: relative;
    overflow: unset;
}

.lifestyle .custom-card a.share-blog {
    min-height: 20px !important;
}










.share-common-home-video .modal .dropleft {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ae0a0b;
    border-top-right-radius: 5px;
}

.share-common-home-video .modal .dropleft .dropbtn {
    background-color: transparent;
    color: #fff;
    padding: 2px 5px;
    margin: 0 !important;
}

.share-common-home-video .modal .dropleft .dropdown-menu {
    right: 0;
    top: 20px;
    padding: 0;
    min-width: 10px;
    background: #fff !important;
    box-shadow: 0 2px 4px #ccc;

}

.share-common-home-video .modal .dropleft .dropdown-menu a {
    border: 0;
    margin: 0;
    padding: 2px 7px !important;
    display: block;
    min-height: 0 !important;
}

.share-common-home-video .modal .dropleft .dropdown-menu a:hover {
    color: #ae0a0b !important;
}

.share-common-home-video .modal .dropleft:hover .dropdown-menu {
    display: block;
}

.share-common-home-video .modal a.share-blog {
    position: relative;
    overflow: unset;
}










.share-common-card-content .dropleft {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ae0a0b;
    border-top-right-radius: 5px;
}

.share-common-card-content .dropleft .dropbtn {
    background-color: transparent;
    color: #fff;
    padding: 2px 5px;
    margin: 0 !important;
}

.share-common-card-content .dropleft .dropdown-menu {
    right: 0;
    top: 20px;
    padding: 0;
    min-width: 10px;
    background: #fff !important;
    box-shadow: 0 2px 4px #ccc;

}

.share-common-card-content .dropleft .dropdown-menu a {
    border: 0;
    margin: 0;
    padding: 2px 7px !important;
    display: block;
    min-height: 0 !important;
}

.share-common-card-content .dropleft .dropdown-menu a:hover {
    color: #ae0a0b !important;
}

.share-common-card-content .dropleft:hover .dropdown-menu {
    display: block;
}

.share-common-card-content a {
    position: relative;
    overflow: unset;
}






.ad-sticky-top {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    /* required */
}



.supplement-tabpane .react-multiple-carousel__arrow {
    top: 18px !important;
    right: 70px !important;
    left: auto !important;
    border-radius: 5px !important;
    background-color: #AE0A0B !important;
}

.supplement-tabpane button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 25px !important;
}

.supplement-tabpane {
    margin: 15px 0;
}

.supplement-tabpane .supplement-category-page {
    padding: 0 15px;
}

.gujarat-metro .metro-tab-bubble {
    color: #ae0a0b;
    border: 2px solid #ae0a0b;
    border-radius: 25px;
    padding: 5px 12px;
    cursor: pointer;
    margin: 10px;
}

.gujarat-metro .metro-tab-bubble.active {
    color: #fff;
    background-color: #ae0a0b;
    border: 2px solid #ae0a0b;
    border-radius: 25px;
    padding: 5px 12px;
    cursor: pointer;
    margin: 10px;
}

.gujarat-metro .metro-tabpane {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 35px;
}

.center.tabpane-samagra {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
}

.gujarat-samagra span.badge {
    height: auto;
}

.gujarat-samagra .internal {
    background-color: transparent;
}

.gujarat-samagra .metro-tabpane {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.gujarat-samagra .metro-tab-bubble {
    color: #ae0a0b;
    border: 2px solid #ae0a0b;
    border-radius: 25px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 3.4px 4px;
    opacity: 1;
    user-select: none;

}

.gujarat-samagra .metro-tab-bubble.active {
    color: #fff;
    background-color: #ae0a0b;
    border: 2px solid #ae0a0b;
    border-radius: 25px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 3.4px 4px;
    opacity: 1;
    user-select: none;
}

.gujarat .post-not-found {
    color: #ae0a0b;
    color: #ae0a0b;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0px;
}

.gujarat-samagra .tabpane-samagra::-webkit-scrollbar {
    height: 0px;
}

/* Track */
.gujarat-samagra .tabpane-samagra::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.gujarat-samagra .tabpane-samagra::-webkit-scrollbar-thumb {
    background: #ae0a0b;
    border-radius: 10px;
}

/* Handle on hover */
.gujarat-samagra .tabpane-samagra::-webkit-scrollbar-thumb:hover {
    background: #ae0a0b;
}



.related-content-alsoread {
    width: 100%;
    border: 1px solid black;
    margin: 15px 0;
    padding: 15px;
    box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
    font-size: 13px;
    border: solid 1px #ddd;
    border-radius: 4px;
}

.related-content-alsoread .d-flex.justify-content-between {
    align-items: center;
}

.related-content-alsoread h4 {
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.6;
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: center;
    border-radius: 3px;
}

.related-content-alsoread .collapse-alsoread-content {
    margin-top: 15px;
}

.related-content-alsoread .collapse-alsoread {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background: #f2f2f2;
    width: 23px;
    height: 23px;
    position: relative;
}

.related-content-alsoread .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.related-content-alsoread .collapse-alsoread-content a {
    color: #000;
}

@media (max-width: 768px) {
    .related-content-alsoread .collapse-alsoread-content ul li {
        margin: 15px 0;
    }
}

.fa.fa-share-alt {
    font-weight: 200;
    font-size: 13px;
}


section.header .topbar {
    border-bottom: solid 1px #e3e3e3;
}

.scroll-container {
    user-select: none;
}

.business-home {
    height: 190px;
    overflow-y: auto;
}

.related-content-alsoread {
    user-select: none;
}

span.collapse-alsoread.collapsed i.fas::before {
    content: "\f067";
}

#not {
    position: sticky;
    left: 0px;
    opacity: 1 !important;
}

.slick-track,
.slick-list {
    display: flex;
    width: auto !important;
}

.menu .collapsing {
    transition: height .15s ease;
}

.metro-inner {
    background-color: #ddd;
    color: #000;
    opacity: 0.5;
    cursor: pointer;
    padding: 7.5px 15px;
}

.metro-inner.active {
    background-color: #ddd;
    color: #ae0a0b;
    opacity: 1;
}

.gujarat ul.center.asd {
    overflow-x: auto;
}

.gujarat ul.center.asd::-webkit-scrollbar {
    height: 0px !important;
}


.topleft.asd {
    top: -10px !important;
    left: 0px !important;
    font-size: 12px !important;
}

.home-over-hide {
    overflow-x: hidden;
}

.content {
    cursor: grab !important;
    overflow: auto;
}

.qr-code-app {
    padding: 0 26px;
}

.qr-code-app img {
    padding: 0 0px;
}

.qr-code-app span {
    font-weight: 700;
    color: #ae0a0b;
    font-size: 14px;
}

section.header .logo {
    background-image: url('https://assets.sandesh.com/images/2023/08/14/e0yxD1TVBWuoUqOKA7qsnf9WG689LCivO8aQKDhP.jpg');
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: auto 100%;
}

.unibotssubhome {
    width: 100%;
    height: 250px;
}


.sport-score-head {
    display: flex;
    justify-content: center;
}

.sport-score-title {
    font-size: 25px;
    color: #161616;
    font-weight: 800;
    position: absolute;
    left: 15px;
}

.sport-score-tabpane span {
    color: #212529;
    margin: 5px 15px;
    cursor: pointer;
}

.active-sport-tab {
    color: #ae0a0b;
    font-weight: 700;
    position: relative;
}

.active-sport-tab::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 6px;
    left: 50%;
    transform: skew(-20deg) translateX(-50%);
    top: 110%;
    background: #ae0a0b;
}

.match-card {
    border-top: 10px solid #ae0a0b;
    border-bottom: 10px solid #ae0a0b;
    border-radius: 10px;
    padding: 15px 20px;
    margin: 5px 15px 25px;
    position: relative;
    box-shadow: 4px 4px 20px 0 rgb(6 6 6 / 15%);
    min-height: 344px;
}

.match-card.match1 {
    height: 344px;
    overflow: hidden;
}

.match-card.match2 {
    height: 401px;
    overflow: hidden;
}

.match-card-top {
    text-transform: uppercase;
    border-bottom: 1px solid;
    padding-bottom: 5px;
}

.match-card-top h5 {
    font-size: 12px !important;
    min-height: 34px;
    height: 100%;
    margin-bottom: 0px;
    color: #050a5c;
}

.match-card-schedule {
    margin-top: 5px;
    color: #161616;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.match-card-results {
    text-align: center;
    color: #050a5c;
    font-size: 14px;
    font-weight: 700;
    margin-top: 2px;
}

.match-card-teams {
    display: flex;
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin: 5px 0;
    padding: 5px 0;
    justify-content: space-between;
    align-items: center;
}

.match-card-team1 {
    width: 80px;
}

.match-card-over {
    font-size: 10px;
}

.team-name {
    color: #161616;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
}

.score-live {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    color: #050a5c;
}

.score-live span {
    font-size: 10px;
    font-weight: 600;
    color: #050a5c;
}

.match-bottom-info {
    font-size: 12px;
    font-weight: 400;
    color: #161616;
}

.match-slider .react-multiple-carousel__arrow {
    position: absolute;
    top: 0px !important;
}

.sport .story p {
    font-size: 15px;
}

@media screen and (max-width: 991px) {
    .sport .react-multi-carousel-track {
        overflow: unset !important;
    }

    .sport-score-title {
        font-size: 16px;
        left: 30px;
    }

    .sport-score-tabpane span {
        margin: 2px 5px;
        font-size: 14px;
    }

    .sport-score-head {
        justify-content: end;
        margin-top: 45px;
    }

    .match-card {
        min-height: 344px;
    }

    .sport-score-tabpane {
        margin-right: 15px;
        margin-top: 7px;
    }
}



/* Election subcomponents  */


.meganews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: flex-start;
}

.meganews .related-blogs:first-child {
    border-top: 0;
}

section.election-bg {
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px
}

.meganews .custom-card .clearfix a:first-child {
    border-top: 0;
}


.main-voting-poll {
    border: 0.5px solid #ae0a0b;
    width: 100%;
}

.heading-vote {
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    padding: 5px;
}

.heading-vote2 {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: x-large;
}

/* th , td{
    padding-top: 10px;
  } */

th,
table {
    border-spacing: 3px;
    border-collapse: collapse;
}

th.district {
    border-collapse: unset;
}

th.district {
    color: white;
    width: 25%;

    padding-bottom: 0%;
}

.Voting-table2 th.district {
    width: 35%;
}

.Voting-table2 th.district span {
    background-color: #000;
    padding: 8px;
    display: inline;
    padding: 7px 20px 7px 11px;
    border-radius: 0px 5px 5px 0px;
}

th.district span {
    background-color: #000;
    padding: 4px;
    display: inline;
    border-radius: 0px 5px 5px 0px;
}

.Voting-table2 th.cong {
    background-color: skyblue;
    padding: 8px;
    text-align: center;
    font-size: 10px;
}

/* th.cong span{
    background-color: skyblue;
    padding:4px;
    text-align: center;
  } */


th.cong {
    color: white;
    width: 19%;
    font-size: 8px;
    padding: 4px;
    /* padding-bottom: 0%; */
    background-color: skyblue;
    text-align: center;
}

.Voting-table2 th.aap {
    background-color: orange;
    padding: 8px;
    text-align: center;
    font-size: 10px;
}

/* th.aap span{
   
    padding:4px;
    text-align: center;
    
  } */
th.aap {
    background-color: orange;
    color: white;
    width: 19%;
    font-size: 8px;
    text-align: center;
    padding-bottom: 0%;
}

.Voting-table2 th.bjp {
    background-color: red;
    padding: 8px;
    text-align: center;
    font-size: 10px;
}


th.bjp {
    padding-bottom: 0%;
    color: white;
    width: 19%;
    text-align: center;
    background-color: red;
    font-size: 8px;

}

.Voting-table2 th.other {
    background-color: grey;
    padding: 8px;
    text-align: center;
    font-size: 10px;
}

td {
    text-align: center;
    padding: 6px;
    font-size: 12px;
    font-weight: 500;

}

th.other {
    background-color: grey;
    padding-bottom: 0%;
    color: white;
    text-align: center;
    width: 20%;
    font-size: 8px;

}

.col-xl-6.polltable {
    padding-left: 5px;
    padding-right: 7px;
    padding-bottom: 10px;
}

td.district {

    border-left: dashed 1px grey;
    border-right: dashed 1px grey;
}

td.cong {

    border-left: dashed 1px grey;
    border-right: dashed 1px grey;
}


tr:last-child {
    border-bottom: none;
}

tr {
    border-bottom: 1px solid black;
    /* padding: 0%; */
}

th.district-name {
    padding-left: 4px;
}

.Voting-table {
    /* border-collapse: unset !important; */
    width: 100%;
    font-size: 10px;


}

.Voting-table2 {
    font-size: 13px !important;

    width: 100%;

}

.votes {
    font-weight: 900;
}

.party-name {
    background: #ae0a0b;
    color: white;
    text-align: center;
    font-size: 18px;
    padding: 8px;
}

p.party-2 {
    margin-right: 15px;
}

p.party-1 {
    margin-left: 14px;
}

.party-name2 {
    background: #ae0a0b;
    color: white;
    text-align: center;
    display: flex;
    font-size: 15px;
    padding: 7px;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
}

.ImageSwastik {
    height: 49px;
    position: absolute;
    width: 39px;
    top: -7px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}

.heading-main {
    position: relative;
    padding-bottom: 10px;
}

.col-xl-12.polltable {
    padding-left: 10px;
    padding-right: 10px;
}

.donut-chart {
    padding-bottom: 10px;
}

.chart {
    padding: 8px;
    /* padding-right: 0; */

}

.donutchart-compo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: center;
    align-items: flex-start;
}

element.style {
    background: rgb(255, 207, 4);
}

li.item-name {
    margin: 3px;
    font-size: 10px;
    font-weight: bold;
}

ul.items-names1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
}

ul.items-names {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
}

.counter {
    position: absolute;
    top: 30%;
    left: 50%;
    bottom: 0%;
    text-align: center;
    font-weight: 800;
    transform: translateX(-50%);
}

p.total {
    border-bottom: 1px solid grey;
    font-size: 11px;
}

p.outof {
    color: grey;
}

.donut-count {
    position: relative;
    font-size: 9px;
}

@media (max-width: 1200px) {
    .party-name2 {
        background: #ae0a0b;
        color: white;
        text-align: center;
        display: flex;
        font-size: 13px;
        padding: 5px;
        justify-content: space-around;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: row;
    }

    .ImageSwastik {
        height: 39px;
        position: absolute;
        width: 26px;
        top: -7px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
}


.Raji-Ka-Rang {
    padding-top: 15px;
    padding-bottom: 15px;
}

.match-card.match2 {
    height: 401px;
    overflow: hidden;
}

.match-card {
    border-top: 10px solid #ae0a0b;
    border-bottom: 10px solid #ae0a0b;
    border-radius: 10px;
    padding: 15px 20px;
    margin: 5px 15px 25px;
    position: relative;
    box-shadow: 4px 4px 20px 0 rgb(6 6 6 / 15%);
}



.marquee-link {
    transition: .3s;
}

.marquee-link:hover {
    color: #ae0a0b;
}


marquee {
    margin: 0 auto;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
}

.marquee:before,
.marquee:after {
    position: absolute;
    top: 0;
    width: 50px;
    height: 30px;
    content: "";
    z-index: 1;
}

.marquee:before {
    left: 0;
}

.marquee:after {
    right: 0;
}

.marquee__content {
    width: 1200%;
    display: flex;
    line-height: 30px;
    animation: marquee 60s linear infinite forwards;
}

.marquee__content ul {
    width: 400%;
    font-size: 17px;
    font-weight: bold;
}

.marquee__content ul li span {
    margin: 0 15px;
}

.marquee__content:hover {
    animation-play-state: paused;
}

.list-inline {
    display: flex;
    justify-content: space-around;
    width: 33.33%;
    list-style: none;
    padding: 0;
    margin: 0;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-66.6%);
    }
}



.iframe-election {
    position: relative;
}

.iframe-election iframe {
    width: 100%;
}

@media (max-width: 1300px) {
    .iframe-election iframe {
        transform: scale(0.7);
        position: absolute;
        top: -94px;
        left: -33px;
        width: 120%;
    }
}

@media (max-width: 1200px) {
    .iframe-election iframe {
        transform: scale(0.7);
        position: absolute;
        top: -94px;
        left: -33px;
        width: 140%;
    }
}

@media (max-width: 991px) {
    .iframe-election iframe {
        transform: scale(1);
        position: unset;
        height: 700px !important;
        width: 100%;
    }

    /* .iframe-election.page iframe {
      transform: scale(0.5);
      height: 1300px !important;
      position: absolute;
      width: 200%;
      left: -161px;
      top: -322px;
    } */
}

@media (max-width: 768px) {
    .iframe-election iframe {
        height: 700px !important;
    }
}

/* @media (max-width: 575px) {
    .iframe-election{
      left: -43px;
      top: -100px;
      position: relative;
      border: none;
    }
    .iframe-election iframe{
      transform: scale(0.7);
      height: 879px !important;
      width: 127%;
    }
  } */

/* @media (max-width: 768px) {
    .iframe-election.page iframe {
      transform: scale(1);
      position: unset;
      width: 100% !important;
    }
  }
  iframe{
    width: 100%;
  } */

.yt_clear {
    width: 100%;
    height: 560px;
    border: 0;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .yt_clear {
        height: 300px;
    }

    section.video-sub .div-height {
        height: auto;
        padding-bottom: 5px;
    }
}

section.video-sub .featured .s-story hr {
    margin: 15px 0 !important;
}



.accordion-body ul {
    font-weight: 500;
    padding-left: 15px;
}

.accordion-body ul li {
    list-style-type: disc;
}

.accordion-body ul li.no-icons-l {
    list-style-type: none !important;
}

.accordion-body ul li.no-icons-l ul {
    margin-left: 15px;
}

.accordion-body ul a {
    color: #748494;
}

.accordion-body ul a:hover {
    color: #AE0A0B;
}

.accordion {
    margin: 100px 0;
    --bs-accordion-color: #748494;
    --bs-accordion-bg: white;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: #ccc;
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #748494;
    --bs-accordion-btn-bg: #fff;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23748494'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231083d6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #89c9f7;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(18, 146, 238, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #1083d6;
    --bs-accordion-active-bg: #e7f4fd;
}

.accordion-button {
    font-weight: 500;
    color: #000 !important;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: var(--bs-accordion-transition);
    transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button {
        -webkit-transition: none;
        transition: none;
    }
}

.accordion-button:not(.collapsed) {
    /* color: #ad0a0a; */
    background-color: var(--bs-accordion-active-bg);
    -webkit-box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    -webkit-transform: var(--bs-accordion-btn-icon-transform);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    -webkit-transition: var(--bs-accordion-btn-icon-transition);
    transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
        -webkit-transition: none;
        transition: none;
    }
}

.accordion-button:hover {
    z-index: 2;
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    -webkit-box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
    border-top: 0;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0;
}

.GaneshChaturthi .caption {
    display: none;
}

section.gallery.GaneshChaturthi button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 15px !important;
}

section.gallery.GaneshChaturthi button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    right: 60px !important;
}

.GaneshChaturthiPage .shadow {
    padding: 0 !important;
    box-shadow: 0 0 0 !important;
}

.photo-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;
    transition: opacity 0.3s linear;
}

.photo-view::after {
    content: '';
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.photo-view img {
    position: relative;
    z-index: 9;
    max-height: 80%;
    max-width: 90%;
}

.photo-view i {
    color: #f00;
    top: 25px;
    position: fixed;
    right: 25px;
    z-index: 999;
    background: #fff;
    border-radius: 50%;
    font-size: 25px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: 400;
    cursor: pointer;
}

.img-zoomer {
    margin-bottom: 25px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}

.img-zoomer imgimg.gallery-comp-sub-img.new_gallery_img {
    padding: 0 !important;
}

.paper_v_plan,
.paper_v_ {
    display: none;
}

.md-loading {
    display: block;
    text-align: center;
    margin-top: 45px;
}

.md-loading::after {
    content: '';
    width: 65px;
    height: 65px;
    display: inline-block;
    margin: 0 auto !important;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: #ae0a0b;
    animation: fa-spin 0.7s linear infinite;
}

.dropdown-menu .align-items-baseline {
    justify-content: space-between;
}

.dropdown-menu .fa-sort-down {
    transform: rotateZ(-90deg) translateX(5px) translateY(-5px);
}