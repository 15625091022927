.subscription-box-v2,
.login-box-v2 {
  padding-right: 17px;
  overflow: hidden !important;
}

.subscription-box-v2 mybot,
.login-box-v2 mybot {
  display: none;
}

.plans .closebtn,
section.login .closebtn {
  margin: 0;
  padding: 0;
  line-height: normal;
  right: 15px;
  font-size: 30px;
  top: 15px;
  color: #fff;
  background-color: #ae0a0b;
  width: 37px;
  height: 37px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}

.plans .closebtn i,
section.login .closebtn i {
  font-weight: 400;
}

section.login .login-main-box {
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
  height: 100% !important;
  transition: all .15s !important;
}

section.login .login-main-box.active {
  visibility: visible;
  opacity: 1;
}

.login .shadow {
  background-color: #ae0a0b;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 35%;
  border-radius: 50%;
  transform: scale(1.5);
  z-index: -1;
}

.pay-popup,
.login .forms {
  width: 400px;
  max-width: 90%;
  margin: auto;
  display: flex;
  min-height: 100%;
  align-items: center;
  padding: 15px 0;
}

.pay-popup {
  max-width: 900px;
  width: 100%;
  padding: 15px;
  justify-content: center;
}

.forms .img-popup {
  text-align: center;
  margin-top: 45px;
}

.login .forms img {
  display: block;
  margin: auto;
  width: 180px;
}

.login .forms img.paper {
  margin-top: 45px;
  width: 100%;
}

.security {
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.security p {
  line-height: 23px !important;
  margin: 0;
}

.security i {
  min-width: 30px;
}

.login .form {
  padding: 15px;
}

.form-content {
  min-height: 50px;
  background-color: #fff;
  position: relative;
  border: 2px solid #333;
  border-radius: 4px;
}

.form-content.true {
  border-color: #ae0a0b;
}

.form-content.email input {
  padding-left: 110px;
}

.form-content.email .flags {
  width: 85px;
}

.form-content .flags {
  z-index: 9;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 75px;
  border: 1px solid #333;
  margin: 10px;
  border-radius: 5px;
  justify-content: space-around;
}

.forms .flags-option img,
.form-content .flags img {
  width: 15px;
  border-radius: 0;
  margin: 0;
}

.flags-option p,
.form-content .flags p {
  margin: 0;
  font-weight: 600;
}

.form-content input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-right: 15px;
  height: 100% !important;
  border: 0 !important;
  border-radius: 5px !important;
  padding-left: 100px;
  font-weight: 600;
}

.form-content input::-webkit-input-placeholder {
  color: #666;
  letter-spacing: normal !important;
}

.flags-option {
  position: absolute;
  border: 1px solid #333;
  background-color: #fff;
  border-radius: 5px;
  left: 10px;
  top: 10px;
  z-index: 91;
  opacity: 0;
  visibility: hidden;
}

.flags-option.true {
  opacity: 1;
  visibility: visible;
}

.flags-option div {
  z-index: 9;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 65px;
  border: 1px solid transparent;
  margin: 0 10px;
  border-radius: 5px;
  justify-content: flex-start;
}

.flags-option div img {
  margin-right: 8px !important;
}

.login .form .btn {
  width: 100%;
  background: #ae0a0b;
  color: #fff;
  font-weight: bold;
  line-height: normal;
  padding: 11px;
}

.form-content.validate {
  border-color: #45ad4f !important;
}

.login .form .btn.btn-invert {
  color: #000 !important;
  margin-top: 15px;
  cursor: pointer !important;
}

.login .form .btn.btn-invert,
.login .form .btn:disabled {
  opacity: 1;
  cursor: not-allowed;
  background: #ccc !important;
}

span.loading-round {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin: 0 !important;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top-color: #000;
  animation: fa-spin 0.7s linear infinite;
}

section.login .login-main-box p.errors {
  background: #dcdcdc;
  color: #333;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px #f1f1f1;
  font-weight: 600;
  margin-bottom: 25px !important;
}

.otp-page .flags {
  opacity: 0;
  visibility: hidden;
}

.otp-validate {
  display: flex;
}

section.login .login-main-box .otp-page input.form-control {
  text-align: center;
  border: 2px solid #666;
  padding: 20px 5px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin: 5px;
  box-shadow: 0 0 0 !important;
}

.otp-page p.info {
  margin-bottom: 25px;
  line-height: 23px !important;
}

.otp-page .time-info {
  margin: 15px 0 35px;
  display: flex;
  justify-content: space-between;
}

.otp-page .time-info p {
  margin: 0;
  font-weight: 600;
  font-size: 11px !important;
  color: #ae0a0b !important;
  cursor: pointer;
}

.otp-page .time-info p:last-child {
  text-align: right;
}

.otp-page .time-info p.disabled {
  color: #bbb !important;
  cursor: not-allowed;
}

.flags:after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  margin-left: 3px;
  transform: scale(0.7);
}

.form-content.email .flags::after {
  margin-left: 0px;
}

.gender-options {
  margin: 15px 0 25px;
  display: flex;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  justify-content: space-between;
}

.gender-options div {
  cursor: pointer;
  border: 2px solid #333;
  border-radius: 4px;
  padding: 5px 16px;
  font-weight: 600;
  min-width: 85px;
}

.gender-options div.selected {
  background: #45ad4f;
  color: #fff;
  border-color: #45ad4f !important;
}

.signup-page .fa-icons-input {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
}

.signup-page .form-content {
  margin-bottom: 15px;
}

.signup-page input {
  text-align: left;
  padding-left: 40px !important;
  letter-spacing: 0 !important;
}

.plans {
  position: relative;
  min-width: 100%;
  display: block;
  overflow: hidden;
  min-height: 100vh;
  border-top: 2px solid #fff;


  height: 100%;
  background-color: #fff;
  border: 0;
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 0;
  overflow-y: auto;
}

.plans img.p-logo {
  display: block;
  margin: auto;
  width: 180px;
}

.plans img.paper {
  width: 100%;
  margin-top: 10px;
  /* position: absolute;
  width: 60%;
  left: 0;
  top: 40%;
  transform: translateY(-50%); */
}

.plans .shadow {
  background-color: #ae0a0b;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 35%;
  border-radius: 50%;
  transform: scale(1.5);
  z-index: -1;
}

.plans .text-top {
  /* width: 400px; */
  /* max-width: 90%; */
  width: 100%;
  margin: auto;
  min-height: 100%;
  align-items: center;
}

.top-heading p {
  color: #ae0a0b;
  font-size: 300%;
  margin-top: 50px;
  font-weight: 600;
}

.top-heading span.is-package {
  cursor: pointer;
  /* box-shadow: 0 6px 0 1px #500000; */
}

.top-heading span {
  transition: all .3s;
  background: #fff;
  color: #ae0a0b;
  user-select: none;
  border: 2px solid #ae0a0b;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 15px;
  display: inline-block;
  font-size: 250%;
  min-width: 100%;
  text-align: center;
  border-radius: 60px;

  background: #ae0a0b;
  color: #fff;
  border-radius: 4px;
}

.top-heading span b {
  display: block;
  font-size: 20px;
  line-height: normal;
}

.points {
  margin-top: 55px;
}

.points b {
  color: #333;
  display: block;
  padding-left: 35px;
  position: relative;
  font-size: 120%;
  margin-bottom: 15px;
  font-weight: 500;
  text-align: left;
}

.points b::before {
  position: absolute;
  left: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
}

.packages {
  background-color: #fff;
  box-shadow: 0 2px 4px #ccc;
  margin: 5px 0 25px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  justify-content: center;
}

.packages .package {
  width: 200px;
  max-width: 50%;
  box-sizing: border-box;
}

.details {
  margin: 5px;
  border: 2px solid #ccc;
  cursor: pointer;
  display: block;
  padding: 10px 10px 5px;
  user-select: none;
  transition: all .3s;
  border-radius: 4px;
  text-align: center;
}

.details:hover {
  border-color: #ae0a0b !important;
}

.details:hover::after {
  background-color: #ae0a0b !important;
}

.details.selected-package {
  position: relative;
  background-color: rgb(174 10 11 / 10%) !important;
  border-color: #ae0a0b !important;
}

.details.selected-package::after {
  background-color: #ae0a0b !important;
}

.details.selected-package::before {
  background-color: #ae0a0b !important;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: -10px;
  top: -10px;
  width: 25px;
  height: 25px;
  text-align: center;
  color: #fff;
  border-radius: 25px;
  line-height: 25px;
  z-index: 9;
}

.details.best-deal {
  border-color: #000;
  position: relative;
}

.details.best-deal::after {
  content: 'Best Deal';
  position: absolute;
  top: 0;
  transition: all .3s;
  font-size: 9px;
  background: #000;
  color: #fff;
  font-weight: 600;
  padding: 5px;
  display: block;
  left: 50%;
  transform: translateX(-50%) translateY(-13px);
  border-radius: 4px;
}

.details b {
  font-size: 25px;
  text-align: left;
  color: #ae0a0b;
  display: block;
}

.details p div.price {
  white-space: nowrap;
  padding-right: 10px;
}

.details p div.text {
  font-size: 12px;
}

.details p {
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.details i {
  text-decoration: line-through !important;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  color: #999;
}

.details span {
  display: inline-block;
  font-size: 11px;
  border-radius: 15px;
  border: 1px solid #ae0a0b;
  margin: 10px auto 5px;
  padding: 2px 8px;
  font-weight: 600;
  color: #ae0a0b;
}

.top-heading.is-Button {
  position: sticky;
  bottom: 0;
  z-index: 9;
}

.packages.true,
.packages.true * {
  cursor: not-allowed;
}

.is-payment .plan-text-crown,
.is-payment img.paper,
.is-payment img.p-logo,
.is-payment .shadow {
  display: none;
}

.is-payment .text-top {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.login-popup-click {
  display: inline-block;
  background: #ae0a0b;
  color: #fff;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 4px #ccc;
  font-size: 19px;
  margin-top: 45px;
}

.payment-initial {
  text-align: center;
}

.payment-initial b {
  display: block;
  color: #ae0a0b;
  margin-top: 45px;
}

.payment-initial p {
  display: block;
  font-weight: 600;
  margin-top: 15px;
}

.fa-success::before {
  content: "\f00c";
}

.fa-failed::before {
  content: "\f00d";
}

.payment {
  text-align: center;
}

.payment i.fa-success {
  background: #139214;
}

.payment i {
  display: inline-block;
  background: #ae0a0b;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 55px;
  border-radius: 75px;
  color: #fff;
  margin-bottom: 25px;
}

.payment h3 {
  margin-bottom: 25px;
  font-weight: bold;
}

.payment p {
  font-size: 18px;
  color: #666;
  margin-bottom: 45px;
  font-weight: 600;
}

.isCatLoad .topleft,
.isCatLoad .colorspan-top-post,
.isCatLoad .categoty-main-post-title,
.isCatLoad p.playicon-subhome,
.isCatLoad .skeleton-sub img,
.isCatLoad .skeleton img,
.isCatLoad .playicon-subhome-subcategory,
.isCatLoad .pagination,
.isCatLoad .caption img {
  display: none !important;
}

.isCatLoad.gujarat .substory .story p,
.isCatLoad .blog-details span,
.isCatLoad .custom-card .color-red,
.isCatLoad .custom-card .ellipsis,
.isCatLoad .blog-details p {
  opacity: 0.4 !important;
}

.login-popup-link {
  display: inline-block;
  color: #ae0a0b !important;
  font-weight: bold;
  font-size: 19px;
}

.flags-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  z-index: 9999;
  display: block;
}

.flags-popup.false {
  display: none;
}

.white-popup {
  width: 350px;
  margin: auto;
  margin-top: 10vh;
  max-height: 80vh;
  border-radius: 4px;
  box-shadow: 0 0 10px #00000042;
  overflow: auto;
  background: #fff;
  max-width: 80%;
}

.white-popup div {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  justify-content: start;
}

.white-popup div p {
  font-weight: 600;
  color: #222;
  padding-left: 15px !important;
  flex: auto;
  margin: 0 !important;
}

.white-popup div img {
  width: 20px !important;
}

.plan-text-crown {
  display: block;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin: 15px 0 0;
}

.plan-text-crown.invert {
  color: #222;
  font-weight: 600;
}

.plan-text-crown i {
  margin-right: 15px;
}

@media (max-width: 768px) {
  .PostLoader {
    padding: 0 15px !important;
  }

  .subscription-box-v2,
  .login-box-v2 {
    padding-right: 0;
  }

  .plans img.p-logo,
  .forms .img-popup img {
    width: 100px;
  }

  .packages {
    margin-top: 10px;
  }

  .plan-text-crown {
    font-size: 16px;
  }

  .plans .text-top {
    width: 100%;
    max-width: 100%;
    /* padding: 15px; */
    margin: 0;
  }
}